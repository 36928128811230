import './login.css';
import logo from '../logo-grey.png';
import React, { useState } from 'react';

import { BsTextareaT } from "react-icons/bs";
import AuthService from "../services/auth.service";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
 

export default function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        login()
    }

    const login = () => {
        AuthService.login(email, password).then(() => {
            // this.props.history.push("/");
            window.location.href = '/shell';
        },
        error => {
            const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            console.log('login', resMessage);
            setLoading(false);
        });
    }

    return (
        <div className="main-wrapper text-center">
            <main className="form-signin">
                {/* <BsTextareaT className="logo" /> */}
                <img src={logo} style={{'width':'200px'}} />
                {/* <h1 className="tagnotes"><span>Tag</span>notes</h1> */}
                <Form className="mt-4" onSubmit={handleSubmit}>
                    <div className="form-floating">
                        <input className="form-control" id="floatingInput" onChange={e => setEmail(e.target.value)} placeholder="Enter email" type="email" value={email} />
                        <label htmlFor="floatingInput">E-Mail Adresse</label>
                    </div>
                    <div className="form-floating">
                        <input className="form-control" id="floatingPassword" onChange={e => setPassword(e.target.value)} placeholder="Password" type="password" value={password} />
                        <label htmlFor="floatingPassword">Passwort</label>
                    </div>
                    {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control onChange={e => setEmail(e.target.value)} placeholder="Enter email" type="email" value={email} />
                        <Form.Text className="text-muted">
                        Deine E-Mail Adresse wird zu keiner Zeit an Dritte weitergegeben
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control onChange={e => setPassword(e.target.value)} placeholder="Password" type="password" value={password} />
                    </Form.Group> */}
                    <Button className="w-100" variant="primary" size="lg" type="submit">
                        {loading && (<span><span className="spinner-border spinner-border-sm"></span> </span>)}
                        Anmelden
                    </Button>
                </Form>
            </main>
        </div>
    );
}