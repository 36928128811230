import './filter.css';
import React, { useContext } from 'react';

import { BiCheckCircle, BiCheckSquare, BiErrorCircle, BiHelpCircle, BiInfoCircle, BiStar, BiSquare} from "react-icons/bi";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faInfo, faExclamation, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle, faCheckSquare, faQuestionCircle, faStar, faSquare } from '@fortawesome/free-regular-svg-icons'
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { App, Check2Square, CheckCircle, CheckCircleFill, Dot, ExclamationCircle, ExclamationCircleFill, InfoCircle, InfoCircleFill, ListTask, QuestionCircle, QuestionCircleFill, Star, StarFill, ToggleOff, ToggleOn, UiChecks, UiRadios } from 'react-bootstrap-icons';
import DatePicker, { registerLocale, setDefaultLocale } from  "react-datepicker";
import de from 'date-fns/locale/de';
registerLocale('de', de)


function Filter(props) {
    function handleDateSelect(value) {
        console.log('filter_handle-date-select', value);
        // setFilter(type, value, e);
        props.setDate(value)
    }

    function handleTagClick(e) {
        e.preventDefault();
        console.log('filter_handle-tag-click');
        props.setTag({ id:null, name:null });
    }

    function handleStarClick(type, value, e) {
        e.preventDefault();
        console.log('filter_handle-star-click');
        setFilter(type, value, e);
    }

    function setOption(option, e) {
        e.stopPropagation();
        console.log('filter_set-option', option)
        let tmpOptions = [...props.opts];
        let found = -1
        if ((found = tmpOptions.indexOf(option)) > -1) {
            tmpOptions.splice(found, 1);
            tmpOptions.push(`-${option}`)
        }
        else if ((found = tmpOptions.indexOf(`-${option}`)) > -1) {
            tmpOptions.splice(found, 1);
            tmpOptions.push(option)
        }
        props.setOpts(tmpOptions);
    }
    
    function setFilter(type, value, e) {
        e.stopPropagation();
        console.log('filter_set-filter', type, value);
        let tmpOptions = [...props.opts];
        let found = -1;

        if (type === 'option' || type === 'reset') {
            if (value === 'check0' || value == 'check1') {
                if ((found = tmpOptions.indexOf(value)) > -1) {
                    tmpOptions.splice(found, 1);
                }
                else {
                    if ((found = tmpOptions.indexOf(value === 'check0' ? 'check1' :'check0')) > -1) {
                        tmpOptions.splice(found, 1);
                        tmpOptions.push('check2'); // incomplete and complete task
                    }
                    else {
                        if ((found = tmpOptions.indexOf('check2')) > -1) {
                            tmpOptions.splice(found, 1);
                            tmpOptions.push(value === 'check0' ? 'check1' :'check0');
                        }
                        else {
                            tmpOptions.push(value); // value is check0 or check1 - incomplete task
                        }
                    }
                }
            }
            else if ((found = tmpOptions.indexOf(value)) > -1) {
                tmpOptions.splice(found, 1);
            }
            else if ((found = tmpOptions.indexOf(`-${value}`)) > -1) {
                tmpOptions.splice(found, 1);
            }
            else {
                tmpOptions.push(value);
            }
        }
        props.setOpts(tmpOptions);
    }

    const RenderDatePickerBtn = React.forwardRef(({ value, onClick }, ref) => (
        <button className={`btn filter-btn btn-text ${value ? 'active pe-4' : ''}`} onClick={onClick} ref={ref} size="md" variant="secondary">
            { value || 'Datum auswählen' }
        </button>
    ))
    
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div id="filterbar">
                        <Button className={`filter-btn me-1 ${props.opts.includes('star') ? 'active' : ''}`} onClick={(e) => handleStarClick('option', 'star', e)} size="md" variant="secondary">
                            <BiStar />
                        </Button>
                        <ButtonGroup className="me-1">
                            <Button className={`filter-btn ${props.opts.includes('check0') || props.opts.includes('check2') ? 'active' : ''}`} onClick={(e) => handleStarClick('option', 'check0', e)} size="md" variant="secondary">
                                <BiSquare />
                            </Button>
                            <Button className={`filter-btn ${props.opts.includes('check1') || props.opts.includes('check2') ? 'active' : ''}`} onClick={(e) => handleStarClick('option', 'check1', e)} size="md" variant="secondary">
                                <BiCheckSquare />
                            </Button>
                        </ButtonGroup>
                        <ButtonGroup aria-label="Basic example">
                            <Button className={`filter-btn ${props.opts.includes('ok') || props.opts.includes('-ok') ? 'ok' : ''}`} onClick={(e) => handleStarClick('option', 'ok', e)} size="md" variant="secondary">
                                <BiCheckCircle />
                                { props.opts.includes('ok') && (
                                    <span onClick={(e) => setOption('ok', e)}>&nbsp;<ToggleOn className="mx-1" /></span>
                                )}
                                { props.opts.includes('-ok') && (
                                    <span onClick={(e) => setOption('ok', e)}>&nbsp;<ToggleOff className="mx-1" /></span>
                                )}
                            </Button>
                            <Button className={`filter-btn ${props.opts.includes('question') || props.opts.includes('-question') ? 'question' : ''}`} onClick={(e) => handleStarClick('option', 'question', e)} size="md" variant="secondary">
                                <BiHelpCircle />
                                { props.opts.includes('question') && (
                                    <span onClick={(e) => setOption('question', e)}>&nbsp;<ToggleOn className="mx-1" /></span>
                                )}
                                { props.opts.includes('-question') && (
                                    <span onClick={(e) => setOption('question', e)}>&nbsp;<ToggleOff className="mx-1" /></span>
                                )}
                            </Button>
                            <Button className={`filter-btn ${props.opts.includes('info') || props.opts.includes('-info') ? 'info' : ''}`} onClick={(e) => handleStarClick('option', 'info', e)} size="md" variant="secondary">
                                <BiInfoCircle />
                                { props.opts.includes('info') && (
                                    <span onClick={(e) => setOption('info', e)}>&nbsp;<ToggleOn className="mx-1" /></span>
                                )}
                                { props.opts.includes('-info') && (
                                    <span onClick={(e) => setOption('info', e)}>&nbsp;<ToggleOff className="mx-1" /></span>
                                )}
                            </Button>
                            <Button className={`filter-btn ${props.opts.includes('exclamation') || props.opts.includes('-exclamation') ? 'exclamation' : ''}`} onClick={(e) => handleStarClick('option', 'exclamation', e)} size="md" variant="secondary">
                                <BiErrorCircle />
                                { props.opts.includes('exclamation') && (
                                    <span onClick={(e) => setOption('exclamation', e)}>&nbsp;<ToggleOn className="mx-1" /></span>
                                )}
                                { props.opts.includes('-exclamation') && (
                                    <span onClick={(e) => setOption('exclamation', e)}>&nbsp;<ToggleOff className="mx-1" /></span>
                                )}
                            </Button>
                        </ButtonGroup>
                        <DatePicker customInput={<RenderDatePickerBtn />} dateFormat="dd. MMM yyyy" isClearable locale="de" selected={props.date} showPopperArrow={false} todayButton="Heute" onChange={(date, e) => {handleDateSelect(date)}} />
                        { props.tag.id && (
                            <>
                                <Button className="filter-btn btn btn-text active" onClick={(e) => handleTagClick(e)} size="md" variant="outline-primary">{ props.tag.name }</Button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default Filter;