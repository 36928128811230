import './sidebar.css';
import React, { useState } from 'react';
import Tags from './tags'
import logo from '../logo-grey.png';
import logoOnly from '../logo-only.png';
// Bootstrap
import Offcanvas from 'react-bootstrap/Offcanvas'
import { ArrowBarLeft, ArrowBarRight, List }  from 'react-bootstrap-icons'

const Sidebar = ({
    dockSidebar, 
    isMobile,
    date,
    display,
    handleDateFilter,
    handleDockSidebarToggle,
    handleTaskFilter,
    opts,
    orderBy,
    orderDirection,
    perPage,
    tag,
    tags,
    tagSearchRef,
    task,
    setDate,
    setDisplay,
    setOpts,
    setOrderBy,
    setOrderDirection,
    setPerPage,
    setTag,
    setTags,
    setTask,
    loading}) => {

    const RenderSidebar = () => {
        const [showSidebar, setShowSidebar] = useState(false)
        const handleClose = () => setShowSidebar(false);
        const handleShow = () => setShowSidebar(true);

        console.log('sidebar_render-sidebar', dockSidebar, isMobile)

        let parsed
        if (dockSidebar && !isMobile) {
            parsed = (
                <div className="col-md-2 tag-container docked ps-0 pe-4">
                    <a href="/shell"><img src={logo} className="logo logo-md fix" style={{'width':'110px'}} /></a>
                    <div id="left-side">
                        <ArrowBarLeft  className="ms-4 mt-2 toggle-sidebar" onClick={(e) => handleDockSidebarToggle(false, e)} />
                        <RenderTags />
                    </div>
                </div>
            )
        }
        else {
            parsed = (
                <div className="floating">
                    <div onClick={handleShow} style={{ 'position':'absolute', 'fontSize':'2em', 'top':'20px', 'left':'20px', 'width':'20px', 'zIndex':'1'}}><List /></div>
                    <Offcanvas show={showSidebar} onHide={handleClose}>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                                <a href="/shell"><img src={logoOnly} style={{'width':'60px'}} /></a>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <div id="left-side">
                                <ArrowBarRight className="ms-4 toggle-sidebar" onClick={(e) => handleDockSidebarToggle(true, e)} />
                                <RenderTags />
                            </div>
                        </Offcanvas.Body>
                    </Offcanvas>
                </div>
            )
        }
        return parsed
    }

    const RenderTags = () => {
        return (
            <Tags 
            date={date} 
            display={display}
            handleDateFilter={handleDateFilter}
            handleTaskFilter={handleTaskFilter}
            opts={opts} 
            orderBy={orderBy} 
            orderDirection={orderDirection}
            perPage={perPage}
            tag={tag} 
            tags={tags} 
            tagSearchRef={tagSearchRef}
            task={task} 
            setDate={setDate} 
            setDisplay={setDisplay} 
            setOpts={setOpts} 
            setOrderBy={setOrderBy} 
            setOrderDirection={setOrderDirection}
            setPerPage={setPerPage}
            setTag={setTag} 
            setTags={setTags} 
            setTask={setTask} 
            loading={loading} />
        )
    }

    return (
        <>
        <RenderSidebar />
        </>
    )
}

export default React.memo(Sidebar);