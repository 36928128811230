import axios from 'axios';

import AuthService from '../services/auth.service';
import { toast } from 'react-toastify'
import CommonService from './common.service';
import configData from "../config.json";

const API_URL = `${configData.API_URL}/tag`;

class TagService {
    setTag(tag) {
        return AuthService.getFreshToken().then(access_token => {
            let url = `${API_URL}/store`;
            return axios.post(url, tag, { blocking: true, headers: { Authorization: `Bearer ${access_token}` }}).then(response => {
                toast.success(`Erfolgreich gespeichert`, {autoClose:1500})
                return response.data;
            },
            error => {
                return CommonService.handleError(error, 'setTag');
            });
        })
    }
}

export default new TagService();
