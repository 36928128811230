import { toast } from 'react-toastify';
import history from 'history/browser';

class CommonService {
    handleError = (error, caller) => {
        let msg = (error.response && error.response.data && error.response.data.message ) || error.message || error.toString();
        console.log('filter-service_handle-error', msg);
        
        const LoginMsg = ({ closeToast, toastProps }) => (
            <div>
                <span><u>{ caller } - Fehler</u></span><br />
                <strong>{ msg }</strong>
                <p>Es wird automatisch auf die Login Seite weitergeleitet</p>
            </div>
        )
        const Msg = ({ closeToast, toastProps }) => (
            <div>
                <span><u>{ caller } - Fehler</u></span><br />
                <strong>{ msg }</strong>
            </div>
        )

        if (caller === 'getUser') {
            // history.push('/login');
            toast(<LoginMsg />, {
                autoClose: 5000,
                // onClose: () => window.location.href = '/login'
            });
        }
        else {
            toast(<Msg />, {
                autoClose: 1500,
            });
        }
        return Promise.reject(msg);
    }
}

export default new CommonService();
