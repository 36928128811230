import './app.css';

import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { createBrowserHistory } from 'history';
import history from 'history/browser';

import AuthService from "./services/auth.service";
import Login from "./components/login";
import Shell from "./components/shell";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'lightgallery.js/dist/css/lightgallery.css';

// import AuthVerify from "./common/auth-verify";
import EventBus from "./common/EventBus";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: undefined
        };

        console.log('app_constructor')
    }

    componentDidMount() {
        console.log('app_component-did-mount_get-current-user');
        const user = AuthService.getCurrentUser();
        // console.log('app_component-did-mount_user', user);

        if (user) {
          this.setState({
            currentUser: user
          });
        }

        // EventBus.on("logout", () => {
        //     this.logOut();
        // });
    }

    componentWillUnmount() {
        EventBus.remove("logout");
    }

    render() {
        const { currentUser } = this.state;

        return (
            <Router history={history}>
                <Switch>
                    <Route path="/shell">
                        <Shell />
                    </Route>
                    <Route path="/login">
                        <Login />
                    </Route>
                    <Route path="/">
                        <Login />
                    </Route>
                </Switch>
                <ToastContainer />
            </Router>
        );
    }
}

export default App;