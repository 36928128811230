import './tags.css';
import React, { useCallback, useContext, useEffect, useRef, useState, memo } from 'react';

import { Placeholder } from 'react-bootstrap';
import { BiCheckCircle, BiCheckSquare, BiChevronRightCircle, BiChevronDownCircle, BiErrorCircle, BiHelpCircle, BiInfoCircle, BiStar, BiSquare, BiDotsVertical} from "react-icons/bi";
import { BookmarkPlus, BookmarksFill, CardChecklist, Check2, Funnel, JournalBookmarkFill, JournalCheck, JournalText, PencilSquare, PinAngleFill, Search, StarFill, ToggleOff, ToggleOn, XCircleFill } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import TagModal from './tag-modal'
import AuthService from '../services/auth.service'
import FilterService from '../services/filter.service'
import TagService from '../services/tag.service'
import UserService from '../services/user.service'
import FormControl from 'react-bootstrap/FormControl'
import moment from 'moment'
import Moment from 'react-moment';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
 
const Tags = memo((props) => {
    const _newTag = { id: null, name: '', pin: false, position: 0, star: false, type: '#'}

    const [ loading, setLoading ] = useState(false);
    const [ selectedTag, setSelectedTag ] = useState(_newTag);
    const [ tab, setTab] = useState(1);
    const [ search, setSearch ] = useState('');
    const [ showModal, setShowModal ] = useState(false);
    const [collapsed, setCollapsed] = useState(null)

    const today = moment().hour(12).minute(0).second(0).toDate()
    const todayCompare = moment(today).format('YYYY-MM-DD')
    const yesterday = moment().hour(12).minute(0).second(0).subtract(1, 'days').toDate()
    const yesterdayCompare = moment(yesterday).format('YYYY-MM-DD')

    const handleLogout = () => {
        AuthService.logout()
        UserService.unsetUser()

        window.location.href = '/'
    }

    const handleOptionClick = (type, value, e) => {
        e.preventDefault();
        console.log('filter_handle-star-click');
        setFilter(type, value, e);
    }

    const handleModalClose = () => setShowModal(false);
    const handleModalShow = () => setShowModal(true);

    // console.log('tags_tag', props.tag);
    const handleTabClick = (key) => {
        console.log('tag_handle-tab-click', key)
        setTab(key)
    }

    const handleTagClick = (tag, e) => {
        e.preventDefault();
        console.log('tags_handle-tag-click', tag);
        setSearch('')
        setTab(1)
        if (tag === null) {
            props.setTag({id: null, name: null, type: null});
        }
        else {
            props.setTag({id: tag.id, name: tag.name, type: tag.type});
        }
    }

    const handleTagSave = () => {
        return TagService.setTag(selectedTag).then(response => {
            return FilterService.getTags().then(result => {
                props.setTags(result)
            }).finally(() => {
                handleModalClose()
            })
        })
    }

    const handleTagSearch = (search) => {
        console.log('tag_handle-tag-search', search)
        setSearch(search ? search.toLowerCase() : '')
        if (search.length) {
            setTab(3)
        }
        else {
            setTab(1)
        }
    }
    
    const handleTagToggle = (tagItem) => {
        setCollapsed(collapsed === tagItem.id ? null : tagItem.id) 
    }

    const openSelectedTag = (tagItem) => {
        setSelectedTag(tagItem)
        handleModalShow()
    }
    
    function setFilter(type, value, e) {
        e.stopPropagation();
        console.log('filter_set-filter', type, value);
        let tmpOptions = [...props.opts];
        let found = -1;

        if (type === 'option' || type === 'reset') {
            if ((found = tmpOptions.indexOf(value)) > -1) {
                tmpOptions.splice(found, 1);
            }
            else if ((found = tmpOptions.indexOf(`-${value}`)) > -1) {
                tmpOptions.splice(found, 1);
            }
            else {
                tmpOptions.push(value);
            }
        }
        props.setOpts(tmpOptions);
    }

    function setOption(option, e) {
        e.stopPropagation();
        console.log('filter_set-option', option)
        let tmpOptions = [...props.opts];
        let found = -1
        if ((found = tmpOptions.indexOf(option)) > -1) {
            tmpOptions.splice(found, 1);
            tmpOptions.push(`-${option}`)
        }
        else if ((found = tmpOptions.indexOf(`-${option}`)) > -1) {
            tmpOptions.splice(found, 1);
            tmpOptions.push(option)
        }
        props.setOpts(tmpOptions);
    }

    return (
        <>
            <div className="ms-4 mt-2">
                <div className="label"><JournalText className="mt--2" /> Journal</div>
                <div className={`label-item ${(props.task === null && props.date === null && props.display !== 'list') ? 'active' : ''}`} onClick={(e) => props.handleDateFilter('all', e)}>Alle</div>
                <div className={`label-item ${(props.task === null && props.date !== null && moment(props.date).format('YYYY-MM-DD') === todayCompare) ? 'active' : ''}`} onClick={(e) => props.handleDateFilter('today', e)}>Heute</div>
                <div className={`label-item ${(props.task === null && props.date !== null && moment(props.date).format('YYYY-MM-DD') === yesterdayCompare) ? 'active' : ''}`} onClick={(e) => props.handleDateFilter('yesterday', e)}>Gestern</div>
                {/* <div className="label-item">Datum</div> */}
                <div className="label"><JournalCheck className="mt--2" /> Tasks</div>
                <div className={`label-item ${props.task && props.task === 'all' ? 'active' : ''}`} onClick={(e) => props.handleTaskFilter('all', e)}>Alle</div>
                <div className={`label-item ${props.task && props.task === 'pending' ? 'active' : ''}`} onClick={(e) => props.handleTaskFilter('pending', e)}>Inbox</div>
                {/* <div className={`label-item ${props.task && props.task === 'current' ? 'active' : ''}`} onClick={(e) => props.handleTaskFilter('current', e)}>Heute</div> */}
                {/* <div className={`label-item ${props.task && props.task === 'overdue' ? 'active' : ''}`} onClick={(e) => props.handleTaskFilter('overdue', e)}>Überfällig</div> */}
                <div className={`label-item ${props.task && props.task.indexOf('week') > -1 ? 'active' : ''}`} onClick={(e) => props.handleTaskFilter('week', e)}>Woche</div>
                {/* <div className={`label-item ${props.task && props.task === 'forecast' ? 'active' : ''}`} onClick={(e) => props.handleTaskFilter('forecast', e)}>In Kürze</div> */}
                <div className={`label-item mt-2 ${(props.task === null && props.date === null && props.display === 'list') ? 'active' : ''}`} style={{'marginLeft':'-7px'}} onClick={(e) => props.handleDateFilter('list', e)}><CardChecklist className="mt--2" /> Dashboard</div>
                <div className="label"><Funnel className="mt--2" /> Filter</div>
                <div className={`label-item star ${props.opts.includes('star') ? 'active' : ''}`} onClick={(e) => handleOptionClick('option', 'star', e)}><BiStar className="mt--2" /> Markiert</div>
                <div className={`label-item exclamation ${props.opts.includes('exclamation') ? 'active' : ''} ${props.opts.includes('-exclamation') ? 'inactive' : ''}`} onClick={(e) => handleOptionClick('option', 'exclamation', e)}>
                    <BiErrorCircle className="mt--2" /> Wichtig
                    { props.opts.includes('exclamation') && (
                        <span onClick={(e) => setOption('exclamation', e)}>&nbsp;<ToggleOn className="mx-1" /></span>
                    )}
                    { props.opts.includes('-exclamation') && (
                        <span onClick={(e) => setOption('exclamation', e)}>&nbsp;<ToggleOff className="mx-1" /></span>
                    )}
                </div>
                <div className={`label-item question ${props.opts.includes('question') ? 'active' : ''} ${props.opts.includes('-question') ? 'inactive' : ''}`} onClick={(e) => handleOptionClick('option', 'question', e)}>
                    <BiHelpCircle className="mt--2" /> Zu klären
                    { props.opts.includes('question') && (
                        <span onClick={(e) => setOption('question', e)}>&nbsp;<ToggleOn className="mx-1" /></span>
                    )}
                    { props.opts.includes('-question') && (
                        <span onClick={(e) => setOption('question', e)}>&nbsp;<ToggleOff className="mx-1" /></span>
                    )}
                </div>
                <div className={`label-item info ${props.opts.includes('info') ? 'active' : ''} ${props.opts.includes('-info') ? 'inactive' : ''}`} onClick={(e) => handleOptionClick('option', 'info', e)}>
                    <BiInfoCircle className="mt--2" /> Informativ
                    { props.opts.includes('info') && (
                        <span onClick={(e) => setOption('info', e)}>&nbsp;<ToggleOn className="mx-1" /></span>
                    )}
                    { props.opts.includes('-info') && (
                        <span onClick={(e) => setOption('info', e)}>&nbsp;<ToggleOff className="mx-1" /></span>
                    )}
                </div>
                <div className={`label-item ok ${props.opts.includes('ok') ? 'active' : ''} ${props.opts.includes('-ok') ? 'inactive' : ''}`} onClick={(e) => handleOptionClick('option', 'ok', e)}>
                    <BiCheckCircle className="mt--2" /> Erledigt
                    { props.opts.includes('ok') && (
                        <span onClick={(e) => setOption('ok', e)}>&nbsp;<ToggleOn className="mx-1" /></span>
                    )}
                    { props.opts.includes('-ok') && (
                        <span onClick={(e) => setOption('ok', e)}>&nbsp;<ToggleOff className="mx-1" /></span>
                    )}
                </div>
                <div className="label"><JournalBookmarkFill className="mt--2" /> Tags</div>
            </div>
            <div id="tags">
                <div className="tag-section mt--2" onClick={(e) => handleTabClick(1, e)}>
                    <Search className="mt--2" />
                    <input className="tag-search-form" placeholder="Alt+Shift+T" onChange={(e) => handleTagSearch(e.target.value)} ref={props.tagSearchRef} value={search} />
                </div>
                { props.tag.name && (
                    <div className="tag-item active my-1" onClick={(e) => handleTagClick(null, e)}><span>{props.tag.type}{props.tag.name} <XCircleFill className="mt--2" /></span></div>
                )}
                { !search.length && (
                    <div className="tag-section mt--2" onClick={(e) => handleTabClick(1, e)}><StarFill className="mt--2" /> Fav</div>
                )}
                { props.tags.map((tagItem) => (
                    tagItem.star && (
                        <div className={`tag-item ${tagItem.name === props.tag.name ? 'active' : ''}`} key={ tagItem.id }>
                            <span onClick={(e) => handleTagClick(tagItem, e)}>{ tagItem.type }{ tagItem.name }</span>
                            <span className="tag-item-edit" onClick={(e) => openSelectedTag(tagItem, e)}><BiDotsVertical /></span>
                        </div>
                    )
                ))}
                { !search.length && (
                    <div className="tag-section mt--2" onClick={(e) => handleTabClick(2, e)}><PinAngleFill className="mt--2" /> Pin</div>
                )}
                { props.tags.map((tagItem) => (
                    tagItem.pin && (
                        <div className={`tag-item ${tagItem.name === props.tag.name ? 'active' : ''}`} key={ tagItem.id }>
                            <span onClick={(e) => handleTagClick(tagItem, e)}>{ tagItem.type }{ tagItem.name }</span>   
                            <span className="tag-item-edit" onClick={(e) => openSelectedTag(tagItem, e)}><BiDotsVertical /></span>
                        </div>
                    )
                ))}
                <div className="tag-section mt--2" onClick={(e) => handleTabClick(3, e)}><BookmarksFill className="mt--2" /> All</div>
                    <>
                        {search && (
                            props.tags.filter(item => item.name.includes(search)).map((tagItem) => (
                                <div className={`tag-item ${tagItem.name === props.tag.name ? 'active' : ''}`} key={ tagItem.id }>
                                    <span onClick={(e) => handleTagClick(tagItem, e)}>{ tagItem.type }{ tagItem.name }</span>
                                    <span className="tag-item-edit" onClick={(e) => openSelectedTag(tagItem, e)}><BiDotsVertical /></span>
                                </div>
                            ))
                        )}
                        {!search && (
                            props.tags.filter(item => !item.parent_id).map((tagItem) => (
                                <>
                                    <div className={`tag-item ${tagItem.name === props.tag.name ? 'active' : ''}`} key={ tagItem.id }>
                                        <span onClick={(e) => handleTagClick(tagItem, e)}>{ tagItem.type }{ tagItem.name }</span>
                                        { props.tags.filter(item => item.parent_id === tagItem.id).length > 0 && (
                                            <>
                                                { collapsed !== tagItem.id && (
                                                    <BiChevronRightCircle className="ms-1 mt--2" onClick={(e) => handleTagToggle(tagItem)} />
                                                )}
                                                { collapsed === tagItem.id && (
                                                    <BiChevronDownCircle className="ms-1 mt--2" onClick={(e) => handleTagToggle(tagItem)} />
                                                )}
                                            </>
                                        )}
                                        <span className="tag-item-edit" onClick={(e) => openSelectedTag(tagItem, e)}><BiDotsVertical /></span>
                                    </div>
                                    { collapsed === tagItem.id && props.tags.filter(item => item.parent_id === tagItem.id).map((tagItem2) => (
                                        <div className="ps-4" key={tagItem2.id}>
                                            <div className={`tag-item ${tagItem2.name === props.tag.name ? 'active' : ''}`} key={ tagItem2.id }>
                                                <span onClick={(e) => handleTagClick(tagItem2, e)}>{ tagItem2.type }{ tagItem2.name }</span>
                                                <span className="tag-item-edit" onClick={(e) => openSelectedTag(tagItem2, e)}><BiDotsVertical /></span>
                                            </div>
                                                
                                                {/* { props.tags.filter(item => item.parent_id === tagItem2.id).map((tagItem3) => (
                                                    <div className="ps-4">
                                                        <div className={`tag-item ${tagItem3.name === props.tag.name ? 'active' : ''}`} key={ tagItem3.id }>
                                                            <span onClick={(e) => handleTagClick(tagItem3, e)}>{ tagItem3.type }{ tagItem3.name }</span>
                                                            <span className="tag-item-edit" onClick={(e) => openSelectedTag(tagItem3, e)}><PencilSquare /></span>
                                                        </div>
                                                    </div>    
                                                )) } */}

                                        </div>    
                                    )) }
                                </>
                            ))
                        )} 
                    </>   
                <div className="tag-section" onClick={(e) => openSelectedTag(_newTag)}><BookmarkPlus className="mt--2" /> Neuer Tag</div>
                <TagModal handleModalClose={handleModalClose} handleTagSave={handleTagSave} selectedTag={selectedTag} setSelectedTag={setSelectedTag} showModal={showModal} tags={props.tags} />
                
                <Button className="ms-2 mb-1 mt-4" size="sm" variant="danger" onClick={(e) => handleLogout(e)}>Abmelden</Button>
            </div>
        </>
    )
})
 
export default React.memo(Tags);