import React, { useCallback, useEffect, memo, useMemo, useState, useRef } from 'react'
import { Calendar3Event, CaretDownFill, CaretLeftFill, CaretRightFill, ChatLeftDots, ChatLeftTextFill, ChevronLeft, ChevronRight, DashCircleDotted, PlusCircleDotted, JournalText, JournalCheck, List, Pen, Pencil, PencilSquare }  from 'react-bootstrap-icons'
import moment from 'moment'
import Item from './note-item'
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

const Notes = memo(({
    display,
    editmode,
    filtertag,
    id,
    notes,
    tag,
    tags,
    task,
    handleReadonlyToggle,
    handleChildDateSelect,
    handleContentSave,
    handleContentCancel,
    handleContentDelete,
    handleExpandThreadToggle,
    handleReadAllToggle,
    handleAllShowThreadsToggle,
    handleTagSelect,
    handleOptionSelect,
    handleDateSelect,
    inputTitleRef,
    inputDueDatePickerRef,
    setTag,
    setTask,
    setWeekOffset,
    weekOffset
}) => {

    const handleWeekSelect = (offset) => {
        let absoluteWeekOffset = weekOffset + offset
        setWeekOffset(absoluteWeekOffset)
        setTask(`week${absoluteWeekOffset > 0 ? '+' : ''}${absoluteWeekOffset != 0 ? absoluteWeekOffset : ''}`)
    }

    let parsed
    if (display === 'column') {
        parsed = (
            <>
                <div className="row">
                    <div style={{'textAlign':'center'}}>
                        <ButtonGroup>
                            <Button onClick={(e) => handleWeekSelect(-1)} size="sm" variant="primary" style={{ 'width':'150px'}}><ChevronLeft /> Vorherige Woche</Button>
                            <Button onClick={(e) => handleWeekSelect(1)} size="sm" variant="primary" style={{ 'width':'150px'}}>Nächste Woche <ChevronRight /></Button>
                        </ButtonGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="column-header">
                            <div className="column-header-1">
                                <span>{moment().add(weekOffset, 'Weeks').startOf('week').format('dd')} </span>
                                {moment().add(weekOffset, 'Weeks').startOf('week').format('D')}{'. '}</div>
                            <div className="column-header-2">
                                {moment().add(weekOffset, 'Weeks').startOf('week').format('MMM')}<br />
                                {moment().add(weekOffset, 'Weeks').startOf('week').format('YYYY')}
                            </div>
                            <br style={{'clear':'both'}} />
                        </div>
                        { notes.filter(item => moment(item.due_at).format('D') == moment().add(weekOffset, 'Weeks').startOf('week').format('D')).map((item) => (
                            (!filtertag || item.tags.filter(tagItem => { return tagItem.id == filtertag }).length > 0) && (
                            <Item
                                display={display}
                                editmode={editmode}
                                id={id}
                                item={item}
                                key={item.id}
                                tag={tag}
                                tags={tags}
                                task={task}
                                type="note"
                                handleReadonlyToggle={handleReadonlyToggle}
                                handleChildDateSelect={handleChildDateSelect}
                                handleContentSave={handleContentSave}
                                handleContentCancel={handleContentCancel}
                                handleContentDelete={handleContentDelete}
                                handleExpandThreadToggle={handleExpandThreadToggle}
                                handleReadAllToggle={handleReadAllToggle}
                                handleAllShowThreadsToggle={handleAllShowThreadsToggle}
                                handleTagSelect={handleTagSelect}
                                handleOptionSelect={handleOptionSelect}
                                handleDateSelect={handleDateSelect}
                                inputTitleRef={inputTitleRef}
                                inputDueDatePickerRef={inputDueDatePickerRef}
                                setTag={setTag}
                                />)
                        ))}
                    </div>
                    <div className="col">
                        <div className="column-header">
                            <div className="column-header-1">
                                <span>{moment().add(weekOffset, 'Weeks').startOf('week').add(1, 'Days').format('dd')} </span>
                                {moment().add(weekOffset, 'Weeks').startOf('week').add(1, 'Days').format('D')}{'. '}</div>
                            <div className="column-header-2">
                                {moment().add(weekOffset, 'Weeks').startOf('week').add(1, 'Days').format('MMM')}<br />
                                {moment().add(weekOffset, 'Weeks').startOf('week').add(1, 'Days').format('YYYY')}
                            </div>
                            <br style={{'clear':'both'}} />
                        </div>
                        { notes.filter(item => moment(item.due_at).format('D') == moment().add(weekOffset, 'Weeks').startOf('week').add(1, 'Days').format('D')).map((item) => (
                            (!filtertag || item.tags.filter(tagItem => { return tagItem.id == filtertag }).length > 0) && (
                            <Item
                                display={display}
                                editmode={editmode}
                                id={id}
                                item={item}
                                key={item.id}
                                tag={tag}
                                tags={tags}
                                task={task}
                                type="note"
                                handleReadonlyToggle={handleReadonlyToggle}
                                handleChildDateSelect={handleChildDateSelect}
                                handleContentSave={handleContentSave}
                                handleContentCancel={handleContentCancel}
                                handleContentDelete={handleContentDelete}
                                handleExpandThreadToggle={handleExpandThreadToggle}
                                handleReadAllToggle={handleReadAllToggle}
                                handleAllShowThreadsToggle={handleAllShowThreadsToggle}
                                handleTagSelect={handleTagSelect}
                                handleOptionSelect={handleOptionSelect}
                                handleDateSelect={handleDateSelect}
                                inputTitleRef={inputTitleRef}
                                inputDueDatePickerRef={inputDueDatePickerRef}
                                setTag={setTag}
                                />)
                        ))}
                    </div>
                    <div className="col">
                        <div className="column-header">
                            <div className="column-header-1">
                                <span>{moment().add(weekOffset, 'Weeks').startOf('week').add(2, 'Days').format('dd')} </span>
                                {moment().add(weekOffset, 'Weeks').startOf('week').add(2, 'Days').format('D')}{'. '}</div>
                            <div className="column-header-2">
                                {moment().add(weekOffset, 'Weeks').startOf('week').add(2, 'Days').format('MMM')}<br />
                                {moment().add(weekOffset, 'Weeks').startOf('week').add(2, 'Days').format('YYYY')}
                            </div>
                            <br style={{'clear':'both'}} />
                        </div>
                        { notes.filter(item => moment(item.due_at).format('D') == moment().add(weekOffset, 'Weeks').startOf('week').add(2, 'Days').format('D')).map((item) => (
                            (!filtertag || item.tags.filter(tagItem => { return tagItem.id == filtertag }).length > 0) && (
                            <Item
                            display={display}
                            editmode={editmode}
                            id={id}
                            item={item}
                            key={item.id}
                            tag={tag}
                            tags={tags}
                            task={task}
                            type="note"
                            handleReadonlyToggle={handleReadonlyToggle}
                            handleChildDateSelect={handleChildDateSelect}
                            handleContentSave={handleContentSave}
                            handleContentCancel={handleContentCancel}
                            handleContentDelete={handleContentDelete}
                            handleExpandThreadToggle={handleExpandThreadToggle}
                            handleReadAllToggle={handleReadAllToggle}
                            handleAllShowThreadsToggle={handleAllShowThreadsToggle}
                            handleTagSelect={handleTagSelect}
                            handleOptionSelect={handleOptionSelect}
                            handleDateSelect={handleDateSelect}
                            inputTitleRef={inputTitleRef}
                            inputDueDatePickerRef={inputDueDatePickerRef}
                            setTag={setTag}
                            />)
                        ))}
                    </div>
                    <div className="col">
                        <div className="column-header">
                            <div className="column-header-1">
                                <span>{moment().add(weekOffset, 'Weeks').startOf('week').add(3, 'Days').format('dd')} </span>
                                {moment().add(weekOffset, 'Weeks').startOf('week').add(3, 'Days').format('D')}{'. '}</div>
                            <div className="column-header-2">
                                {moment().add(weekOffset, 'Weeks').startOf('week').add(3, 'Days').format('MMM')}<br />
                                {moment().add(weekOffset, 'Weeks').startOf('week').add(3, 'Days').format('YYYY')}
                            </div>
                            <br style={{'clear':'both'}} />
                        </div>
                        { notes.filter(item => moment(item.due_at).format('D') == moment().add(weekOffset, 'Weeks').startOf('week').add(3, 'Days').format('D')).map((item) => (
                            (!filtertag || item.tags.filter(tagItem => { return tagItem.id == filtertag }).length > 0) && (
                            <Item
                            display={display}
                            editmode={editmode}
                            id={id}
                            item={item}
                            key={item.id}
                            tag={tag}
                            tags={tags}
                            task={task}
                            type="note"
                            handleReadonlyToggle={handleReadonlyToggle}
                            handleChildDateSelect={handleChildDateSelect}
                            handleContentSave={handleContentSave}
                            handleContentCancel={handleContentCancel}
                            handleContentDelete={handleContentDelete}
                            handleExpandThreadToggle={handleExpandThreadToggle}
                            handleReadAllToggle={handleReadAllToggle}
                            handleAllShowThreadsToggle={handleAllShowThreadsToggle}
                            handleTagSelect={handleTagSelect}
                            handleOptionSelect={handleOptionSelect}
                            handleDateSelect={handleDateSelect}
                            inputTitleRef={inputTitleRef}
                            inputDueDatePickerRef={inputDueDatePickerRef}
                            setTag={setTag}
                            />)
                        ))}
                    </div>
                    <div className="col">
                        <div className="column-header">
                            <div className="column-header-1">
                                <span>{moment().add(weekOffset, 'Weeks').startOf('week').add(4, 'Days').format('dd')} </span>
                                {moment().add(weekOffset, 'Weeks').startOf('week').add(4, 'Days').format('D')}{'. '}</div>
                            <div className="column-header-2">
                                {moment().add(weekOffset, 'Weeks').startOf('week').add(4, 'Days').format('MMM')}<br />
                                {moment().add(weekOffset, 'Weeks').startOf('week').add(4, 'Days').format('YYYY')}
                            </div>
                            <br style={{'clear':'both'}} />
                        </div>
                        { notes.filter(item => moment(item.due_at).format('D') == moment().add(weekOffset, 'Weeks').startOf('week').add(4, 'Days').format('D')).map((item) => (
                            (!filtertag || item.tags.filter(tagItem => { return tagItem.id == filtertag }).length > 0) && (
                            <Item
                            display={display}
                            editmode={editmode}
                            id={id}
                            item={item}
                            key={item.id}
                            tag={tag}
                            tags={tags}
                            task={task}
                            type="note"
                            handleReadonlyToggle={handleReadonlyToggle}
                            handleChildDateSelect={handleChildDateSelect}
                            handleContentSave={handleContentSave}
                            handleContentCancel={handleContentCancel}
                            handleContentDelete={handleContentDelete}
                            handleExpandThreadToggle={handleExpandThreadToggle}
                            handleReadAllToggle={handleReadAllToggle}
                            handleAllShowThreadsToggle={handleAllShowThreadsToggle}
                            handleTagSelect={handleTagSelect}
                            handleOptionSelect={handleOptionSelect}
                            handleDateSelect={handleDateSelect}
                            setTag={setTag}
                            inputTitleRef={inputTitleRef}
                            inputDueDatePickerRef={inputDueDatePickerRef}
                            />)
                        ))}
                    </div>
                </div>
            </>
        )
    }
    else {
        parsed = (
            <>
                { Array.isArray(notes) && notes.map((item, index) => (
                    (!filtertag || item.tags.filter(tagItem => { return tagItem.id == filtertag }).length > 0) && (
                        <Item
                            display={display}
                            editmode={editmode}
                            id={id}
                            item={item}
                            key={item.id}
                            tag={tag}
                            tags={tags}
                            task={task}
                            type="note"
                            handleReadonlyToggle={handleReadonlyToggle}
                            handleChildDateSelect={handleChildDateSelect}
                            handleContentSave={handleContentSave}
                            handleContentCancel={handleContentCancel}
                            handleContentDelete={handleContentDelete}
                            handleExpandThreadToggle={handleExpandThreadToggle}
                            handleReadAllToggle={handleReadAllToggle}
                            handleAllShowThreadsToggle={handleAllShowThreadsToggle}
                            handleTagSelect={handleTagSelect}
                            handleOptionSelect={handleOptionSelect}
                            handleDateSelect={handleDateSelect}
                            inputTitleRef={inputTitleRef}
                            inputDueDatePickerRef={inputDueDatePickerRef}
                            setTag={setTag}
                            />
                    )    
                ))}
            </>
        )
    }

    return parsed
})

export default React.memo(Notes);