import './shell.css'
import './notes.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'quill-mention/dist/quill.mention.css'
import 'react-quill/dist/quill.bubble.css'
import 'react-datepicker/dist/react-datepicker.css'

import React, { useCallback, useEffect, memo, useMemo, useState, useRef } from 'react'
import ReactQuill, {Quill} from 'react-quill'
import { LightgalleryProvider } from "react-lightgallery"
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Badge from 'react-bootstrap/Badge'
import { CSSTransition } from 'react-transition-group'
import { toast } from 'react-toastify'
import { useMediaQuery } from 'react-responsive'
import QuillMention from 'quill-mention'
import Hotkeys from 'react-hot-keys'
import Dropdown from 'react-bootstrap/Dropdown'
import Filter from './filter'
import FormControl from 'react-bootstrap/FormControl';
import moment from 'moment'
import Moment from 'react-moment'
import NoteOptions from './note-options'
import Item from './note-item'
import Notes from './notes'
import Sidebar from './sidebar'
import NoteService from '../services/note.service'
import NoteTags from './note-tags'
import Offcanvas from 'react-bootstrap/Offcanvas'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Pagination from 'react-bootstrap/Pagination'
import Summary from './summary'
import Tags from './tags'
import Tooltip from 'react-bootstrap/Tooltip'
import { Calendar3Event, CaretDownFill, CaretLeftFill, CaretRightFill, ChatLeftDots, ChatLeftTextFill, ChevronLeft, ChevronRight, DashCircleDotted, PlusCircleDotted, JournalText, JournalCheck, List, Pen, Pencil, PencilSquare, Funnel, FunnelFill, Info, InfoSquare, CardChecklist }  from 'react-bootstrap-icons'
import { BiCheckCircle, BiCheckSquare, BiErrorCircle, BiHelpCircle, BiInfoCircle, BiStar, BiSquare} from "react-icons/bi";
import { BsTextareaT } from "react-icons/bs";
import AuthService from '../services/auth.service'
import FilterService from '../services/filter.service'
import UserService from '../services/user.service'
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ProgressBar from 'react-bootstrap/ProgressBar'
import DatePicker, { registerLocale, setDefaultLocale } from  "react-datepicker";
import 'moment/locale/de';

import de from 'date-fns/locale/de';
registerLocale('de', de)
moment().locale('de');




/**
 * NotePaging
 * @param {*} param0 
 * @returns 
 */
const NotePaging = ({handlePageSelect, opts, page, pageSearchRef, paging, search, setSearch, tag}) => {
    const handleSearchSubmit = (e) => {
        if (e.key === 'Enter') {
            console.log('shell_note-paging_handle-search-submit')
            setSearch(e.target.value || null)
        }
    }

    let active = page;
    let numbers = [];
    for (let number = 1; number <= paging.last_page; number++) {
        numbers.push(number)
    }
    return (
        <div id="paging" className="text-center">
            <OverlayTrigger key="pageSearchRef" placement="top" trigger={["hover", "hover"]} overlay={<Tooltip id={`pageSearchRef-top`}>In allen gefundenen Notizen suchen (Alt+Shift+N)</Tooltip>}>
                <input className="py-1" type="text" onKeyUp={(e) => handleSearchSubmit(e)} ref={pageSearchRef} placeholder={`${paging.total} Notizen gefunden (Alt+Shift+F)`} style={{'background':'inherit', 'border':'0', 'textAlign':'center', 'width':'100%'}} />
            </OverlayTrigger>
            { paging.last_page > 1 && numbers.map(number => (
                <Button className={`${number === active ? 'active' : ''}`} key={number} onClick={() => { handlePageSelect(number)}} size="sm" variant="link">{number}</Button>
            ))}
        </div>
    )
}

/**
 * Parent
 * @returns 
 */
const Parent = () => {
    // Getter/Setter
    const [date, setDate] = useState(null)
    const [display, setDisplay] = useState('text')
    const [dockSidebar, setDockSidebar] = useState(true)
    const [editmode, setEditmode] = useState({ id:null, type:null, active:false})
    const [filtertag, setFiltertag] = useState('')
    const [input, setInput] = useState(false)
    const [loading, setLoading] = useState(false)
    const [note, setNote] = useState({ id:null, check:null, content:'', readonly:false, tags:[], title:'', type:'note', users:[] })
    const [notes, setNotes] = useState([])
    const [opts, setOpts] = useState([])
    const [orderBy, setOrderBy] = useState('created_at')
    const [orderDirection, setOrderDirection] = useState('desc')
    const [page, setPage] = useState(1)
    const [paging, setPaging] = useState({current_page:1, last_page:1, total:1})
    const [perPage, setPerPage] = useState(25)
    const [search, setSearch] = useState(null)
    const [tag, setTag] = useState({ id: null, name: null})
    const [tagcrumbs, setTagcrumbs] = useState([])
    const [tagcrumbsVisible, setTagcrumbsVisible] = useState(false)
    const [tags, setTags] = useState([])
    const [task, setTask] = useState(null)
    const [user, setUser] = useState({})
    const [weekOffset, setWeekOffset] = useState(0)

    // Ref
    const inputDueDatePickerRef = useRef(null)
    const inputTitleRef = useRef(null)
    const inputNewDueDatePickerRef = useRef(null)
    const inputNewTitleRef = useRef(null)
    const pageSearchRef = useRef(null)
    const tagSearchRef = useRef(null)
    
    // Variables
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` })
    const parser = new DOMParser()
    const parserIncludes = ['p', 'div', 'ul', 'ol']
    const today = moment().hour(12).minute(0).second(0).toDate()
    const yesterday = moment().hour(12).minute(0).second(0).subtract(1, 'days').toDate()

    useEffect(() => {
        activateEditmode()
        AuthService.getFreshToken().then(response => {
            if (!response) {
                console.log('shell_use-effect_get-fresh-token_error')
            }
            else {
                console.log('shell_use-effect_get-fresh-token_success')
                setPage(1) // Reset paging
                let promisses = [
                    UserService.getUser(),
                    FilterService.getTags(),
                    getNotes(1),
                ]
                Promise.all(promisses).then(results => {
                    console.log('shell_use-effect_get-fresh-token_promise-all_then', results)
                    setUser(results[0])
                    setTags(results[1])
                    setNotes(results[2].data)
                    setPaging({current_page: results[2].current_page, last_page: results[2].last_page, total: results[2].total})
                    setTagcrumbs(getTaglist(results[2].data))
                })
                .catch(error => {
                    console.log('shell_use-effect_get-fresh-token_promise-all_catch', error)
                }).finally(() => {
                    deactiveEditmode()
                    pageSearchRef.current?.focus();
                })
            }
        })
        .catch(error => {
            console.log('shell_use-effect_get-fresh-token_error', error)
        })
    }, [display, opts, tag.id, date, search, task]);   

    const activateEditmode = (id, type) => {
        let tmp = {...editmode}
        tmp.id = id
        tmp.type = type
        tmp.active = true
        setEditmode(tmp)

        if (id !== null) {
            setNotes(prevItems => prevItems.map(item => {
                if (item.id !== id) {
                    return item
                } 
                else { 
                    let tmpItem = {...item}
                    tmpItem.readonly = false
                    return tmpItem
                }
            }))
        }
    }

    const containerClasses = () => {
        let classes = 'main-container '
        if (display === 'list') {
            classes += 'col-lg-12 col-xl-12 col-xxl-10'
        }
        else if (display === 'column') {
            classes += 'col-lg-12 col-xl-12 col-xxl-12'
        }
        else {
            classes += 'col-lg-11 col-xl-10 col-xxl-9'
        }
        return classes
    }

    const extendNote = (item, type) => {
        item.image = item.image || null
        item.originalContent = item.content
        item.originalTitle = item.title
        item.readAll = false
        item.readonly = true
        item.showAllThreads = false
        item.thread = {id: null, content: null}
        item.title = item.title || ''
        item.toDateCreatedAt = moment(item.created_at).toDate()
        item.toDateDueAt = item.due_at ? moment(item.due_at).toDate() : null
        item.toDateUpdatedAt = moment(item.updated_at).toDate()
        item.type = type

        extendParsedContent(item)
    }

    const extendParsedContent = (item) => {
        let parsedContent = parser.parseFromString(item.content, "text/html").all
        let parsedContentLength = 0
        item.parsedContent = ''
        for (let i = 3; i < parsedContent.length; i ++) {
            if (parsedContentLength < 200) {
                if (parserIncludes.includes(parsedContent[i].localName)) {
                    item.parsedContent += parsedContent[i].outerHTML
                    parsedContentLength += parsedContent[i].outerText.length
                }
            }
        }
        item.parsedTasks = ''
        item.numTasks = 0
        item.checkedTasks = 0
        item.uncheckedTasks = 0
        for (let i = 3; i < parsedContent.length; i ++) {
            if (parsedContent[i].localName === 'ul' && (parsedContent[i].outerHTML.indexOf('data-checked="false"') > -1 || parsedContent[i].outerHTML.indexOf('data-checked="true"') > -1)) {
                if (parsedContent[i].outerHTML.indexOf('data-checked="false"') > -1) {
                    item.uncheckedTasks += parsedContent[i].children.length
                    item.parsedTasks += parsedContent[i].outerHTML
                }
                else {
                    item.checkedTasks += parsedContent[i].children.length
                }
                item.numTasks += parsedContent[i].children.length
                // item.parsedTasks += parsedContent[i].outerHTML
            }
        }
        if (item.children) {
            item.children.forEach((child, childIndex) => {
                extendParsedContent(child)
                if (task && child.uncheckedTasks > 0) {
                    child.expanded = true
                }
                else if (!task && childIndex === 0) {
                    child.expanded = true
                }
                else {
                    child.expanded = false
                }
                item.numTasks += child.numTasks
                item.checkedTasks += child.checkedTasks
                item.uncheckedTasks += child.uncheckedTasks
            })
        }
        item.completeTasksPercent = parseInt((item.checkedTasks / item.numTasks) * 100)
    }

    const deactiveEditmode = () => {
        setNotes(prevItems => prevItems.map(item => {
            if (item.id !== editmode.id) {
                return item
            } 
            else { 
                let tmpItem = {...item}
                tmpItem.readonly = true
                return tmpItem
            }
        }))

        let tmp = {...editmode}
        tmp.id = null
        tmp.type = null
        tmp.active = false
        setEditmode(tmp)
        setInput(false)
    }

    const findTagsInContent = (content) => {
        // let regex = /<span class=[\"']?mention[\"']? data-index=[\"'](.*)[\"'] data-denotation-char=[\"'](.*)[\"'] data-id=[\"'](.*)[\"'] data-value=[\"'](.*)[\"'].*>/ig
        // let regex = /<span\sclass\=\"mention\"([A-Za-z0-9 _"=@#]*)data-id\=\"([A-Za-z0-9 _]*)\"/g
        // let matches = []
        // while (regex.exec(content)) {
        //     matches.push(RegExp.$3);
        // }
        // console.log(matches)
        // return matches
        const regex = /<span\sclass\=\"mention\"([A-Za-z0-9 _\-"=@#]*)data-id\=\"([A-Za-z0-9 _\-"=@#]*)\" ([A-Za-z0-9 _\-"=@#]*)>/g
        const str = content
        let m
        let matches = []
        while ((m = regex.exec(str)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (m.index === regex.lastIndex) {
                regex.lastIndex ++
            }
            // The result can be accessed through the `m`-variable.
            if (!matches.includes(m[2])) {
                matches.push(m[2])
            }
            // m.forEach((match, groupIndex) => {
            //     console.log(`Found match, group ${groupIndex}: ${match}`)
            // })
        }
        return matches
    }

    const getEditmodeItem = () => {
        let noteItem = null
        setNotes(prevItems => prevItems.map(item => {
            if (item.id === editmode.id) {
                noteItem = {...item}
            }
            return item
        }))

        return noteItem
    }

    const getNotes = (pageNumber) => {
        let notasks = '' // ',-check'
        return FilterService.getNotes(pageNumber, perPage, orderBy, orderDirection, `${opts.join(',')}${notasks}`, tag.id, date, task, search).then(result => {
            result.data.forEach(item => {
                extendNote(item, 'note')
                item.children.forEach(child => {
                    extendNote(child, 'note')
                })
            })
            return result
        })
    }

    const getTaglist = (notes) => {
        let taglist = []
        notes.forEach(note => { // loop notes
            note.tags.forEach(tag => { // loop note tags
                let found = taglist.filter(tagitem => {
                    return tagitem.id === tag.id
                })
                if (found.length === 0) {
                    tag.count = 1
                    taglist.push(tag)
                }
                else {
                    found[0].count = found[0].count + 1
                }
            })
        })

        taglist.sort((a, b) => {
            return a.name - b.name
        });

        // Wenn in der Tagliste nicht der aktuell gefilterte subtag enthalten ist, schmeiß ihn raus
        // let found = taglist.filter(tagitem => {
        //     return tagitem.id === service.subtag
        // })
        // if (!found.length) {
        //     service.subtag = null
        // }
        console.log('taglist', taglist)
        return taglist
    }

    const handleChildDateSelect = useCallback((childId, noteId, date) => {
        console.log('shell_parent_handle-child-date-select', date)
        
        setNotes(prevItems => prevItems.map(item => {
            if (item.id !== noteId) {
                return item
            } 
            else { 
                let tmpItem = {...item}
                tmpItem.children.forEach(citem => {
                    if (citem.id === childId) {
                        citem['toDateCreatedAt'] = date
                        citem['created_at'] = moment(date).utc().format('YYYY-MM-DD HH:mm:ss')
                    }
                })
                return tmpItem
            }
        }))
    }, [])


    const handleContentCancel = useCallback((id, type) => {
        console.log('shell_parent_handle-content-cancel', id, type)
        if (window.confirm('Sicher das die Bearbeitung abgebrochen werden soll?')) {
            let tmpItem = null
            if (id !== null) {
                setNotes(prevItems => prevItems.map((item, index) => {
                    if (item.id !== id) {
                        return item
                    } 
                    else { 
                        tmpItem = {...item}
                        tmpItem.content = tmpItem.originalContent
                        tmpItem.readonly = true
                        tmpItem.title = tmpItem.originalTitle
                        return tmpItem
                    }
                }))
            }
            deactiveEditmode()
        }
    }, [])

    const handleContentDelete = useCallback((id, type) => {
        if (window.confirm('Sicher der Eintrag gelöscht werden soll?')) {
            deactiveEditmode()
            NoteService.deleteNote(id).then(response => {
                getNotes(page).then(result => {
                    setNotes(result.data)
                })
            }).catch(error => {
                console.log('shell_parent-delete-note_catch', error)
            }).finally(() => {
                // toast.dismiss(toastId)
            })
        }
    }, [tag, opts])

    const handleContentSave = useCallback((noteItem, e, reload = true) => {
        const id = toast.loading(<div>Notiz wird gespeichert...</div>)

        console.log('shell_parent_handle-content-save', noteItem, e)
        if (e != undefined) {
            e.stopPropagation()
        }

        // Get value from uncontrolled input
        noteItem.title = noteItem.id === null ? inputNewTitleRef.current.value : inputTitleRef.current.value
        // Remove due date if item is not check, otherwise it messes up my sorting
        if (noteItem.check === null) {
            noteItem.due_at = null
        }
        else {
            if (noteItem.id === null && inputNewDueDatePickerRef.current) {
                noteItem.toDateDueAt = inputNewDueDatePickerRef.current.props.selected
            }
            else if (noteItem.id !== null && inputDueDatePickerRef.current) {
                noteItem.toDateDueAt = inputDueDatePickerRef.current.props.selected
            }
            if (noteItem.toDateDueAt) {
                noteItem.due_at = moment(noteItem.toDateDueAt).utc().hour(18).minute(0).second(0).format('YYYY-MM-DD HH:mm:ss')
            }
    
            if (!noteItem.content && !noteItem.title) {
                toast.warning(<div>Es wurde <u>kein Inhalt</u> in der Notiz erfasst. <br />Die Notiz wurde nicht gespeichert</div>, {autoClose:4000})
                return deactiveEditmode()
            }
        }

        if (noteItem.image) {
            let match = noteItem.image.match(/\<img.+src\=(?:\"|\')(.+?)(?:\"|\')(?:.+?)\>/)
            if (!noteItem.hasOwnProperty('images')) {
                noteItem.images = []    
            }
            noteItem.images.push({
                source: match[1]
            })
            console.log(noteItem.image)
        }
        
        let tagsInContent = findTagsInContent(noteItem.content)
        if (tagsInContent.length) {
            // Add Tags in Note
            tagsInContent.forEach(tagId => {
                if (!noteItem.tags.some(tagItem => tagItem.id == tagId)) {
                    console.log('shell_parent_handle-content-save_tag-mission', tagId)
                    let found = tags.filter(item => { return item.id == tagId})
                    if (found) {
                        noteItem.tags.push(found[0])
                        toast.success(`Tag ${found[0].type}${found[0].name} automatisch eingefügt`, {autoClose:2000})
                    }
                }
            })
            // Remove Tags in Note
            // let removeTags = []
            // noteItem.tags.forEach((tagItem, tagIndex) => {
            //     if (!tagsInContent.some(tagId => tagId == tagItem.id)) {
            //         removeTags.push(tagIndex)
            //     }
            // })
            // removeTags.reverse()
            // removeTags.forEach(tagIndex => {
            //     noteItem.tags.splice(tagIndex, 1)
            // })
        }

        noteItem.readonly = true
        let tmpItem = updateNote(noteItem)
        if (tmpItem !== null) {
            console.log('shell_parent_handle-content-save_save-note', 'before')
            return saveNote(tmpItem, reload).then(result => {
                console.log('shell_parent_handle-content-save_save-note', 'after')
                toast.update(id, { render: <div>Notiz wurde erfolgreich gespeichert</div>, type: "success", isLoading: false, autoClose:2000 });
                return deactiveEditmode()
            })
        }
        else {
            return deactiveEditmode()
        }
    }, [tags]) // note

    const handleDateFilter = useCallback((value, e) => {
        let date = value
        console.log('shell_handle-date-select', value, date);
        if (value === 'all' || value === 'list') {
            date = null
        }
        else if (value === 'today') {
            date = today
        }
        else if (value === 'yesterday') {
            date = yesterday
        }

        let tmpOptions = [...opts]
        // Remove all check Options
        let chk = ['check0', 'check1', 'check2', '-check0', '-check1', '-check2', '-info', '-ok']
        chk.forEach(key => {
            let found = -1
            if ((found = tmpOptions.indexOf(key)) > -1) {
                tmpOptions.splice(found, 1)
            }
        })
        if (value === 'list') {
            tmpOptions.push('-check1')
            tmpOptions.push('-info')
            tmpOptions.push('-ok')
        }
        setOpts(tmpOptions)
        if (value === 'all' && tag.id === null) { // All unfiltered Tag
            setOrderBy('created_at')
            setOrderDirection('desc')
        }
        else if (value === 'list') { // Dashboard
            setOrderBy('check,pin,due_at,star,exclamation,question,info,ok,updated_at')
            setOrderDirection('desc,desc,asc,desc,desc,desc,desc,desc,desc')
        }
        else {
            setOrderBy('pin,created_at')
            setOrderDirection('desc,desc')
        }
        setPerPage(value === 'list' ? 50 : 25)
        setDate(date)
        setDisplay(value === 'list' ? 'list' : 'text')
        setTask(null)
    },[opts])

    const handleDateSelect = useCallback((noteItem, key, date, save = false) => {
        console.log('shell_parent_handle-date-select', date)
        noteItem[key] = date
        // noteItem[key === 'toDateCreatedAt' ? 'created_at' : 'due_at'] = moment(date).utc().format('YYYY-MM-DD HH:mm:ss')
        if (key === 'toDateCreatedAt') {
            noteItem['created_at'] = moment(date).utc().format('YYYY-MM-DD HH:mm:ss')    
        }
        else {
            noteItem['due_at'] = moment(date).utc().hour(18).minute(0).second(0).format('YYYY-MM-DD HH:mm:ss')
        }
        let tmpItem = updateNote(noteItem, null, null)
        if (tmpItem !== null && save) {
            return saveNote(tmpItem)
        }
    }, [date, opts, tag, task])

    const handleDockSidebarToggle = useCallback((value, e) => {
        setDockSidebar(value)
    }, [])

    const handleExpandThreadToggle = useCallback((noteItem, childItem) => {
        console.log('shell_parent_handle-expand-thread-toggle', childItem)
        setNotes(prevItems => prevItems.map(item => {
            if (item.id !== noteItem.id) {
                return item
            } 
            else { 
                let tmpItem = {...item}
                tmpItem.children.forEach(child => {
                    if (child.id === childItem.id) {
                        child.expanded = childItem.expanded ? false : true
                    }
                })
                return tmpItem
            }
        }))
    }, [editmode])

    const handleInputToggle = useCallback((value) => {
        console.log('shell_parent_handle-input-toggle', value)
        setInput(value)
        if (value) {
            activateEditmode(null, 'note')
            inputNewTitleRef.current?.focus();
        }
        else {
            deactiveEditmode()
        }
    }, [input])

    const handleOptionSelect = useCallback((id, noteItem, opt) => {
        console.log('shell_parent_handle-opt-select', opt)
        updateNote(noteItem, null, opt)
    }, [note]) // note

    const handlePageSelect = useCallback((pageNumber) => {
        console.log('shell_parent_handle-page-select', pageNumber)
        activateEditmode()
        setPage(pageNumber)
        getNotes(pageNumber).then(result => {
            console.log('shell_parent_handle-page-select_result', result)
            setNotes(result.data)
        }).finally(() => {
            deactiveEditmode()
        })
    })

    const handleReadAllToggle = useCallback(noteItem => {
        console.log('shell_parent_handle-expand-thread-toggle', noteItem)
        setNotes(prevItems => prevItems.map(item => {
            if (item.id !== noteItem.id) {
                return item
            } 
            else { 
                let tmpItem = {...item}
                tmpItem.readAll = noteItem.readAll ? false : true
                return tmpItem
            }
        }))
    }, [editmode])

    const handleAllShowThreadsToggle = useCallback(noteItem => {
        console.log('shell_parent_handle-show-all-threads-toggle', noteItem)
        setNotes(prevItems => prevItems.map(item => {
            if (item.id !== noteItem.id) {
                return item
            } 
            else { 
                let tmpItem = {...item}
                tmpItem.showAllThreads = noteItem.showAllThreads ? false : true
                return tmpItem
            }
        }))
    }, [editmode])

    const handleReadonlyToggle = useCallback((noteItem) => {
        console.log('shell_parent_handle-readonly-toggle')
        if (editmode.active) {
            if (window.confirm('Sicher das die Bearbeitung abgebrochen werden soll?')) {
                deactiveEditmode()
                activateEditmode(noteItem.id, noteItem.type)
            }
        }
        else {
            activateEditmode(noteItem.id, noteItem.type)
        }
    }, [editmode])

    const handleSetFiltertag = (id) => {
        setFiltertag(id === filtertag ? '' : id)
    }

    const handleShortcutKeys = useCallback((keyName, e, handle) => {
        e.stopPropagation()
        console.log('shell_parent_handle-shortcut-keys', keyName, e, handle)
        if (keyName === 'alt+shift+s' && editmode.active) {
            if (editmode.active && editmode.id !== null) {
                // Find readonly Item
                let noteItem = getEditmodeItem()
                if (noteItem !== null) {
                    handleContentSave(noteItem)
                } 
            }
            else {
                handleContentSave(note)
            }
        }
        if (keyName === 'esc' && editmode.active) {
            console.log('shell_parent_handle-shortcut-keys', keyName, editmode)
            handleContentCancel(editmode.id, editmode.type)
        }
        if (keyName === 'alt+shift+d' && editmode.active) {
            let noteItem = getEditmodeItem()
            handleContentDelete(noteItem.id, noteItem.type)
        }
        if (keyName === 'alt+shift+f') {
            pageSearchRef.current?.focus();
        }
        if (keyName === 'alt+shift+n') {
            // let noteItem = getEditmodeItem()
            if (editmode.active && editmode.id === null) {
                if (!note.content && !note.title) {
                    handleInputToggle(false)    
                }
                else {
                    handleContentSave(note).then(() => {
                        handleInputToggle(true)    
                    })
                }
            }
            else if (editmode.active && editmode.id !== null) {
                let noteItem = getEditmodeItem()
                if (noteItem !== null) {
                    handleContentSave(noteItem).then(() => {
                        handleInputToggle(true)    
                    })
                } 
            }
            else {
                handleInputToggle(true)
            }
        }
        if (keyName === 'alt+shift+t') {
            tagSearchRef.current?.focus();
        }
    }, [editmode, note, tag, opts, orderBy, orderDirection])

    const handleTagcrumbsVisible = () => {
        setTagcrumbsVisible(tagcrumbsVisible ? false : true)
    }

    const handleTaskFilter = useCallback((value, e) => {
        console.log('tag_handle-task-select', value);
        // setFilter('option', 'check0', e)
        // let tmpOptions = [...opts];
        // let found = -1
        // if ((found = tmpOptions.indexOf('check0')) === -1) {
        //     tmpOptions.push('check0');
        //     setOpts(tmpOptions);
        // }
        let tmpOptions = [...opts]
        // Remove all check Options
        let chk = ['check0', 'check1', 'check2', '-check0', '-check1', '-check2', '-info', '-ok']
        chk.forEach(key => {
            let found = -1
            if ((found = tmpOptions.indexOf(key)) > -1) {
                tmpOptions.splice(found, 1)
            }
        })
        // if (value === 'list') {
            tmpOptions.push('check0')
            tmpOptions.push('-info')
            tmpOptions.push('-ok')
        // }
        setOpts(tmpOptions)
        setOrderBy('pin,due_at,star,exclamation,question,info,ok')
        setOrderDirection('desc,asc,desc,desc,desc,desc,desc')
        setPerPage(50)
        setDate(null)
        setDisplay(value.indexOf('week') > -1 ? 'column' : 'list')
        setTask(value)
    },[opts])
    
    const handleTagSelect = useCallback((id, noteItem, tagItem) => {
        console.log('shell_parent_handle-tag-select', tagItem)
        // NoteItem is null on quill mention input
        if (noteItem === null) {
            if (editmode.active) {
                noteItem = getEditmodeItem()
            }
            else {
                noteItem = {...note}
            }
        }
        if (noteItem !== null) {
            updateNote(noteItem, tagItem, null)
        }
    }, [note]) // note

    const saveNote = useCallback((item, reload = true) => {
        return NoteService.setNote(item).then(response => {
            // Only reload Notes after inserting a new Notes 
            // Don´t Update Object in Array, since this will overwrite all other unsaved changes in other Objects
            if (item.id === null) {
                let tmpItem = {...note}
                tmpItem.check = null
                tmpItem.content = ''
                // tmpItem.exclamation = false
                // tmpItem.info = false
                // tmpItem.ok = false
                // tmpItem.question = false
                // tmpItem.readonly = false
                tmpItem.tags = []
                tmpItem.title = ''
                tmpItem.users= []
                // toast.success('Gespeichert', {autoClose:1000, containerId:toastId})
                // Reset Input Note
                setNote(tmpItem)
            }
            if (reload) {
                return getNotes(1).then(result => {
                    setNotes(result.data)
                    setPaging({current_page: result.current_page, last_page: result.last_page, total: result.total})
                })
            }
        }).catch(error => {
            console.log('shell_parent-save-note_catch', error)
        }).finally(() => {
            // toast.dismiss(toastId)
            deactiveEditmode()
        })
    }, [date, opts, search, tag, task]) // note

    const setNoteOption = (item, opt) => {
        if (opt !== null && opt != undefined) {
            if (opt === 'check') {
                if (item[opt] === null) {
                    item[opt] = false
                    // item.toDateDueAt = new Date()
                    let date = new Date()
                    item.toDateDueAt = moment(date).hour(20).minute(0).second(0).toDate()
                    item.due_at = moment(date).utc().format('YYYY-MM-DD HH:mm:ss')
                    
                    // service.orderByPopover.isOpen = false;
                }
                else if (item[opt] === false) {
                    item[opt] = true
                }
                else {
                    item[opt] = null
                }
            }
            else {
                item[opt] = item[opt] ? false : true
            }
        }
        return item
    }

    const setNoteTag = (item, tagItem) => {
        if (tagItem !== null && tagItem != undefined) {
            console.log('shell_set-note-tag', item, tagItem)
            let foundIndex = item.tags.findIndex(t => t.id === tagItem.id)
            if (foundIndex === -1) {
                item.tags.push(tagItem)
            }
            else {
                item.tags.splice(foundIndex, 1)
            }
        }
        return item
    }

    const updateNote = (noteItem, tagItem, opt) => {
        console.log('shell_update-note', noteItem)
        let tmpItem = null
        if (noteItem.id === null) {
            tmpItem = updateTmpNoteItem({...note}, noteItem)
            tmpItem = setNoteOption(tmpItem, opt)
            tmpItem = setNoteTag(tmpItem, tagItem)
            setNote(tmpItem)
        }
        else {
            setNotes(prevItems => prevItems.map(item => {
                if (item.id !== noteItem.id) {
                    return item
                } 
                else { 
                    tmpItem = updateTmpNoteItem({...item}, noteItem)
                    tmpItem = setNoteOption(tmpItem, opt)
                    tmpItem = setNoteTag(tmpItem, tagItem)
                    return tmpItem
                }
            }))
        }
        return tmpItem
    }

    const updateTmpNoteItem = (tmpItem, noteItem) => {
        tmpItem.check = noteItem.check
        tmpItem.content = noteItem.content
        tmpItem.created_at = noteItem.created_at
        tmpItem.due_at = noteItem.due_at // Due Date is uncontrolled input and it´s value is set to toDateDueAt
        tmpItem.exclamation = noteItem.exclamation
        tmpItem.image = noteItem.image || null
        tmpItem.info = noteItem.info
        tmpItem.originalContent = noteItem.content
        tmpItem.ok = noteItem.ok
        tmpItem.parsedTasks = noteItem.parsedTasks
        tmpItem.pin = noteItem.pin
        tmpItem.question = noteItem.question
        tmpItem.readonly = noteItem.readonly
        tmpItem.star = noteItem.star
        tmpItem.title = noteItem.title
        tmpItem.toDateCreatedAt = noteItem.toDateCreatedAt
        if (noteItem.id === null && inputNewDueDatePickerRef.current) {
            tmpItem.toDateDueAt = inputNewDueDatePickerRef.current.props.selected
        }
        else if (noteItem.id !== null && inputDueDatePickerRef.current) {
            tmpItem.toDateDueAt = inputDueDatePickerRef.current.props.selected
        }
        return tmpItem
    }

    return (
        <Hotkeys keyName="alt+shift+s,esc,alt+shift+d,alt+shift+f,alt+shift+n,alt+shift+t" allowRepeat={true} filter={(e) => { return true }}  onKeyDown={(keyName, e, handle) => handleShortcutKeys(keyName, e, handle)}>                                
            <LightgalleryProvider lightgallerySettings={{}} galleryClassName="light-gallery" >
                <CSSTransition in={editmode.active} timeout={500} classNames="editmode">
                    <div className={`container-fluid ${editmode.active ? 'editmode': ''}`}>
                        <div className="row">
                            <Sidebar 
                                dockSidebar={dockSidebar} 
                                isMobile={isMobile}
                                date={date} 
                                display={display}
                                handleDateFilter={handleDateFilter}
                                handleDockSidebarToggle={handleDockSidebarToggle}
                                handleTaskFilter={handleTaskFilter}
                                opts={opts} 
                                orderBy={orderBy} 
                                orderDirection={orderDirection}
                                perPage={perPage}
                                tag={tag} 
                                tags={tags} 
                                tagSearchRef={tagSearchRef}
                                task={task} 
                                setDate={setDate} 
                                setDisplay={setDisplay} 
                                setOpts={setOpts} 
                                setOrderBy={setOrderBy} 
                                setOrderDirection={setOrderDirection}
                                setPerPage={setPerPage}
                                setTag={setTag} 
                                setTags={setTags} 
                                setTask={setTask} 
                                loading={loading} />
                            
                            <div className={(dockSidebar && !isMobile) ? 'col-md-10' : 'col-md-12'}>
                                <div className="row">
                                    <div className={containerClasses()}>
                                        <div className="headline">
                                            {/* Headline */}
                                            <h1 className="text-center">
                                                { task && (
                                                    <>
                                                        <JournalCheck className="mt--8" onClick={(e) => handleDateFilter('all', e)} style={{'cursor':'pointer'}} />{ !tag.name && <span>&nbsp;Tasks </span> }
                                                    </> 
                                                ) }
                                                { !task && display === 'text' && ( 
                                                    <>
                                                        <JournalText className="mt--8" onClick={(e) => handleTaskFilter('all', e)} style={{'cursor':'pointer'}} />{ !tag.name && <span>&nbsp;Journal </span> }
                                                    </> 
                                                ) }
                                                { !task && display === 'list' && ( 
                                                    <>
                                                        <CardChecklist className="mt--8" onClick={(e) => handleTaskFilter('all', e)} style={{'cursor':'pointer'}} />{ !tag.name && <span>&nbsp;Dashboard </span> }
                                                    </> 
                                                ) }
                                                { tag.name && (<span>{tag.name} </span> )}
                                                { opts.length > 0 && (
                                                    <span style={{'fontSize':'20px'}}>
                                                        { opts.includes('star') && (<BiStar className="color-star" />)}
                                                        { opts.includes('exclamation') && (<BiErrorCircle className="color-exclamation" />)}
                                                        { opts.includes('question') && (<BiHelpCircle className="color-question" />)}
                                                        { opts.includes('info') && (<BiInfoCircle className="color-info" />)}
                                                        { opts.includes('ok') && (<BiCheckCircle className="color-ok" />)}
                                                    </span>
                                                )}
                                            </h1>

                                            {/* Paging */}
                                            <NotePaging 
                                                opts={opts} 
                                                page={page} 
                                                pageSearchRef={pageSearchRef} 
                                                paging={paging} 
                                                handlePageSelect={handlePageSelect} 
                                                search={search} 
                                                setSearch={setSearch} 
                                                tag={tag} />

                                            {/* Add Note */}
                                            <div className={`text-center ${paging.last_page == 1 ? 'mt-3 mb-4' : 'mt-1 mb-2'}`}>
                                                {!input && (
                                                    <OverlayTrigger key="PlusCircleDotted" placement="top" overlay={<Tooltip id={`PlusCircleDotted-top`}>Neue Notiz (Alt+Shift+N)</Tooltip>}>
                                                        <PlusCircleDotted className="m-1 add-new" style={{'cursor':'pointer', 'fontSize':'2.5em'}} title="Alt+Shift+N" onClick={(e) => handleInputToggle(true)} />
                                                    </OverlayTrigger>
                                                )}
                                                {input && (
                                                    <DashCircleDotted className="m-1 abort-add-new" style={{'cursor':'pointer', 'fontSize':'2.5em'}} title="Alt+Shift+N" onClick={(e) => handleInputToggle(false)} />
                                                )}
                                            </div>

                                            {/* New Note */}
                                            <CSSTransition in={input} timeout={500} classNames="inputmode">
                                                <div className={`inputmode`}>
                                                    {/* <RenderNoteItem id="null" item={note} key="null" /> */}
                                                    <Item
                                                        display={display}
                                                        editmode={editmode}
                                                        id={null}
                                                        item={note}
                                                        tag={tag}
                                                        tags={tags}
                                                        task={task}
                                                        handleReadonlyToggle={handleReadonlyToggle}
                                                        handleChildDateSelect={handleChildDateSelect}
                                                        handleContentSave={handleContentSave}
                                                        handleContentCancel={handleContentCancel}
                                                        handleContentDelete={handleContentDelete}
                                                        handleExpandThreadToggle={handleExpandThreadToggle}
                                                        handleReadAllToggle={handleReadAllToggle}
                                                        handleAllShowThreadsToggle={handleAllShowThreadsToggle}
                                                        handleTagSelect={handleTagSelect}
                                                        handleOptionSelect={handleOptionSelect}
                                                        handleDateSelect={handleDateSelect}
                                                        inputTitleRef={inputNewTitleRef}
                                                        inputDueDatePickerRef={inputNewDueDatePickerRef}
                                                        setTag={setTag}
                                                        />
                                                </div>
                                            </CSSTransition>

                                            {/* Breadcrumbs */}
                                            { (tagcrumbsVisible && tagcrumbs && tagcrumbs.length > 0) && (
                                                <>
                                                    <Breadcrumb>
                                                        { tagcrumbs.map(tagcrumb => (
                                                            tagcrumb.type === '@' && (<Breadcrumb.Item active={tagcrumb.id === filtertag} key={`tagcrumb_${tagcrumb.id}`} onClick={(e) => handleSetFiltertag(tagcrumb.id)}>{tagcrumb.type}{tagcrumb.name} <Badge bg={tagcrumb.id === filtertag ? 'primary': 'secondary'}>{tagcrumb.count}</Badge></Breadcrumb.Item>)
                                                        ))}
                                                    </Breadcrumb>
                                                    <Breadcrumb>
                                                        { tagcrumbs.map(tagcrumb => (
                                                            tagcrumb.type === '#' && (<Breadcrumb.Item active={tagcrumb.id === filtertag} key={`tagcrumb_${tagcrumb.id}`} onClick={(e) => handleSetFiltertag(tagcrumb.id)}>{tagcrumb.type}{tagcrumb.name} <Badge bg={tagcrumb.id === filtertag ? 'primary': 'secondary'}>{tagcrumb.count}</Badge></Breadcrumb.Item>)
                                                        ))}
                                                    </Breadcrumb>
                                                </>
                                            ) }

                                            {/* Page Info */}
                                            <OverlayTrigger key="pageInfoRef" placement={`${display === 'list' ? 'bottom' : ''}${display === 'column' ? 'left' : ''}${display === 'text' ? 'top' : ''}`} trigger={["click"]} overlay={
                                                    <Tooltip className="page-info-tooltip" id={`pageInfoRef-placement`}>
                                                        <div className="text-start px-4 pt-2">
                                                            {/* Journal ungefiltert nach Tag */}
                                                            { task === null && display === 'text' && tag.id === null && (
                                                                <span>
                                                                    <u>Sortierung</u>:
                                                                    <ul>
                                                                        <li>Erstellungsdatum</li>
                                                                    </ul>
                                                                </span>
                                                            )}
                                                            {/* Journal gefiltert nach Tag */}
                                                            { task === null && display === 'text' && tag.id !== null && (
                                                                <span>
                                                                    <u>Sortierung</u>:
                                                                    <ul>
                                                                        <li>Angeheftet</li>
                                                                        <li>Erstellungsdatum</li>
                                                                    </ul>
                                                                </span>
                                                            )}
                                                            {/* Tasks */}
                                                            { task !== null && (
                                                                <>
                                                                <p>Es werden nur unerledigte Aufgaben angezeigt.</p><p>Standardmäßig werden die Markierungen erledigt und informativ ausgeblendet</p>
                                                                <span>
                                                                    <u>Sortierung</u>:
                                                                    <ul>
                                                                        <li>Angeheftet</li>
                                                                        <li>Erledigt bis</li>
                                                                        <li>Markiert</li>
                                                                        <li>Wichtig</li>
                                                                        <li>Zu klären</li>
                                                                        <li>Informativ</li>
                                                                        <li>Erledigt</li>
                                                                    </ul>
                                                                </span>
                                                                </>
                                                            )}
                                                            {/* Dashboard */}
                                                            { task === null && display === 'list' && (
                                                                <>
                                                                <p>Standardmäßig werden die Markierungen erledigt und informativ ausgeblendet</p>
                                                                <span>
                                                                    <u>Sortierung</u>:
                                                                    <ul>
                                                                        <li>Aufgabe (Ja/Nein)</li>
                                                                        <li>Angeheftet</li>
                                                                        <li>Erledigt bis</li>
                                                                        <li>Markiert</li>
                                                                        <li>Wichtig</li>
                                                                        <li>Zu klären</li>
                                                                        <li>Informativ</li>
                                                                        <li>Erledigt</li>
                                                                        <li>Zuletzt aktualisiert</li>
                                                                    </ul>
                                                                </span>
                                                                </>
                                                            )}
                                                        </div>
                                                    </Tooltip>
                                                }>
                                                <InfoSquare className="toggle-icon toggle-page-info" />
                                            </OverlayTrigger>

                                            {/* Toggle Tagcrumbs */}
                                            { !tagcrumbsVisible && (
                                                <Funnel className="toggle-icon toggle-tagcrumbs" onClick={(e) => handleTagcrumbsVisible()} />
                                            )}
                                            { tagcrumbsVisible && (
                                                <FunnelFill className="toggle-icon toggle-tagcrumbs" onClick={(e) => handleTagcrumbsVisible()} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={containerClasses()}>
                                        
                                        <Notes
                                            display={display}
                                            editmode={editmode}
                                            filtertag={filtertag}
                                            id={null}
                                            notes={notes}
                                            tag={tag}
                                            tags={tags}
                                            task={task}
                                            handleReadonlyToggle={handleReadonlyToggle}
                                            handleChildDateSelect={handleChildDateSelect}
                                            handleContentSave={handleContentSave}
                                            handleContentCancel={handleContentCancel}
                                            handleContentDelete={handleContentDelete}
                                            handleExpandThreadToggle={handleExpandThreadToggle}
                                            handleReadAllToggle={handleReadAllToggle}
                                            handleAllShowThreadsToggle={handleAllShowThreadsToggle}
                                            handleTagSelect={handleTagSelect}
                                            handleOptionSelect={handleOptionSelect}
                                            handleDateSelect={handleDateSelect}
                                            setTag={setTag}
                                            setTask={setTask}
                                            setWeekOffset={setWeekOffset}
                                            weekOffset={weekOffset}
                                            inputTitleRef={inputTitleRef}
                                            inputDueDatePickerRef={inputDueDatePickerRef}
                                            />
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </CSSTransition>
            </LightgalleryProvider>
        </Hotkeys>
    )
}
export default Parent;