import React, { useCallback, useContext, useEffect, useRef, useState, memo } from 'react';

import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'

const TagModal = memo(({handleModalClose, handleTagSave, selectedTag, setSelectedTag, showModal, tags}) => {
    const handleTagChange = useCallback((key, value, e) => {
        console.log('tags_handle-tag-change', key, value)
        e.persist()
        let tagItem = {...selectedTag}
        tagItem[key] = value
        setSelectedTag(tagItem)
    }, [selectedTag])
    
    return (
        <Modal show={showModal} size="sm" onHide={handleModalClose}>
            <Modal.Header closeButton>
                <Modal.Title>{selectedTag.name || 'Neuer Tag'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Tag Name" onChange={(e) => handleTagChange('name', e.target.value, e)} value={selectedTag.name} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="parent_id">
                        <Form.Label>Übergeordneter Tag</Form.Label>
                        <Form.Select onChange={(e) => handleTagChange('parent_id', e.target.value, e)} value={selectedTag.parent_id}>
                            <option value=""></option>
                            { tags.filter(item => !item.parent_id).map((tagItem) => (
                                tagItem.id !== selectedTag.id && (
                                    <option value={ tagItem.id }>{ tagItem.name }</option>
                                )
                            )) }
                        </Form.Select>    
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="type">
                        <Form.Label>Typ</Form.Label>
                        <Form.Select onChange={(e) => handleTagChange('type', e.target.value, e)} value={selectedTag.type}>
                            <option value="#">#</option>
                            <option value="@">@</option>
                        </Form.Select>    
                    </Form.Group>

                    <Form.Check checked={selectedTag.star} type="switch" id="selectedTag-star" onChange={(e) => handleTagChange('star', e.target.checked, e)} label="Fav" />
                    <Form.Check checked={selectedTag.pin} type="switch" id="selectedTag-pin" onChange={(e) => handleTagChange('pin', e.target.checked, e)} label="Pin" />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleModalClose}>Abbrechen</Button>
                <Button variant="primary" onClick={handleTagSave}>Speichern</Button>
            </Modal.Footer>
        </Modal>
    )
})

export default React.memo(TagModal);