import AuthService from "../services/auth.service";

export default function authHeader() {
    const user = JSON.parse(localStorage.getItem('user'))

    // return new Promise( (resolve, reject) => {
    //     if (user && user.access_token) {
    //         const token = JSON.parse(atob(user.access_token.split('.')[1]))

    //         if (Date.now() >= token.exp * 1000) {
    //             console.log('auth-header_expired')
    //             return AuthService.refresh(user.access_token).then(response => {
    //                 if (response) {
    //                     resolve({ Authorization: 'Bearer ' + response })
    //                 }
    //                 else {
    //                     reject({})
    //                 }
    //             })
    //         }
    //         resolve({ Authorization: 'Bearer ' + user.access_token })
    //     }
    //     else {
    //         reject({})
    //     }
    // });






    if (user && user.access_token) {
        const token = JSON.parse(atob(user.access_token.split('.')[1]))

        if (Date.now() >= token.exp * 1000) {
            console.log('auth-header_expired')
            return AuthService.refresh(user.access_token)
        }

        return { Authorization: 'Bearer ' + user.access_token } // for Spring Boot back-end
        // return { 'x-access-token': user.access_token };       // for Node.js Express back-end
    } else {
        return {}
    }
}
