import axios from 'axios';
import authHeader from './auth-header';
import CommonService from './common.service';
import configData from "../config.json";
import moment from 'moment'

const API_URL = `${configData.API_URL}`;

class FilterService {
    constructor () {
        this.getNotes = (page, perPage = 25, orderBy = null, orderDirection = null, options = null, tag = null, date = null, task = null, search = null) => {
            let url = `${API_URL}/notes?page=${page}&perPage=${perPage}`;
            url += (orderBy !== null ? '&orderBy=' + orderBy : '');
            url += (orderDirection !== null ? '&orderDirection=' + orderDirection : '');
            url += (date !== null ? '&date=' + moment(date).format('YYYY-MM-DD') : '');
            url += (options !== null && options.replace(',', '').length ? '&option=' + options : '');
            url += (tag !== null ? '&tag=' + tag : '');
            url += (task !== null ? '&task=' + task : '');
            url += (search !== null ? '&search=' + search : '');

            return axios.get(url, { headers: authHeader() }).then(response => {
                // Bei einem Suchtext wird kein Array sondern eine Objekt Auflistung zurückgegeben
                if (!Array.isArray(response.data.data)) {
                    let data = []
                    for (var prop in response.data.data) {
                        data.push(response.data.data[prop]);
                    }
                    response.data.data = data
                }
                return response.data;
            },
            error => {
                return CommonService.handleError(error, 'getNotes');
            });
        }
        this.getTags = () => {
            return axios.get(`${API_URL}/tags`, { headers: authHeader() }).then(response => {
                return response.data;
            },
            error => {
                return CommonService.handleError(error, 'getTags');
            });
        }
        
    }
}

export default new FilterService();
