import './summary.css';
import React,{ } from 'react';
import { Link } from "react-router-dom";
import { BoxArrowRight } from 'react-bootstrap-icons';

import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
 
function Summary() {
    const logOut = () => {
        AuthService.logout();
        UserService.unsetUser();
    }

    return (
        <div className="logout">
            <Link to={"/login"} onClick={logOut}><BoxArrowRight /></Link>
        </div>
    );
}
 
export default Summary;