import axios from 'axios';
import authHeader from './auth-header';
import CommonService from './common.service';
import configData from "../config.json";

const API_URL = `${configData.API_URL}/user`;

class UserService {
    constructor () {
        var _user = null;

        this.getUser = () => {
            if (_user) {
                return Promise.resolve(_user);
            }
            else {
                return axios.get(API_URL, { headers: authHeader() }).then(response => {
                    _user = response.data;
                    return _user
                },
                error => {
                    console.log('shell_get-user_error', error);
                    // window.location.href = '/login';
                    return CommonService.handleError(error, 'getUser');
                });
            }
        }
        this.unsetUser = () => {
            _user = null;
        }
    }

    
}

export default new UserService();
