import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import './index.css';
import App from './app';
import reportWebVitals from './reportWebVitals';

var numberOfAjaxCallPending = 0;
var numberOfBlockCallPending = 0;

axios.interceptors.request.use((config) => {
    // console.log('app_axios-interceptor-request_config', config)
    numberOfAjaxCallPending ++
    if (config.blocking) {
        numberOfBlockCallPending ++
    }
    if (!config.blocking && numberOfBlockCallPending > 0) {
        let url = config.url.split('/')
        console.log('app_axios-interceptor-request_wait', url[url.length - 1].split('?')[0])
        return new Promise(resolve => setTimeout(() => resolve(config), 600));
    }
    // console.log('shell_axios-interceptor-request_number-of-ajax-call-pending', numberOfAjaxCallPending)
    return config
}, function (error) {
    return Promise.reject(error)
});


axios.interceptors.response.use((response) => {
    // console.log('app_axios-interceptor-response', response)
    numberOfAjaxCallPending--;
    if (response.config.blocking) {
        numberOfBlockCallPending --
    }
    // console.log('shell_axios-interceptor-response_number-of-ajax-call-pending', numberOfAjaxCallPending);
    let url = response.config.url.split('/')
    console.log('app_axios-interceptor-response', url[url.length - 1].split('?')[0])

    if (numberOfAjaxCallPending == 0) {
        //hide loader
    }
    return response;
}, function (error) {
    let url = error.config.url.split('/')
    console.log('app_axios-interceptor-error', url[url.length - 1].split('?')[0])
    numberOfAjaxCallPending--;
    if (error.config.blocking) {
        numberOfBlockCallPending --
    }
    return Promise.reject(error);
});

ReactDOM.render(
    // <React.StrictMode>
        <App />
    // </React.StrictMode>
    ,document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
