import './note-tags.css';
import React, { useState } from 'react';
import { Check2, XCircleFill } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import FormControl from 'react-bootstrap/FormControl';
 
const NoteTags = ({id, item, tag, tags, handleTagSelect, setTag}) => {
    const handleTagClick = (tagItem) => {
        console.log('note-tags_handle-tag-click', tagItem)
        if (tagItem.id === tag.id) {
            setTag({id: null, name: null});
        }
        else {
            setTag({id: tagItem.id, name: tagItem.name});
        }
    }
    const tagToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a className='note-tags-btn' href='' ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }}>
          {children}
          &#x25bc;
        </a>
    ))
    const tagMenu = React.forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
            const [value, setValue] = useState('');
            return (
            <div ref={ref} style={style} className={className} aria-labelledby={labeledBy} >
                <FormControl autoFocus className="mx-3 my-2 w-auto form-control-sm search-tag" placeholder="Hier suchen..." onChange={(e) => setValue(e.target.value)} value={value} />
                <ul className="list-unstyled">
                    {React.Children.toArray(children).filter((child) =>
                        !value || child.props.children[0].toLowerCase().includes(value),
                    )}
                </ul>
            </div>
            );
        },
    )

    return (
        <div className="note-tags">
            { item.readonly && (
                <>
                    <ButtonGroup className={`mt-1 ${item.tags.filter(tagItem => { return tagItem.type === '#' }).length ? 'me-1' : ''}`}>
                        { item.tags.map((tagItem) => (tagItem.type === '#' && (
                            <Button tag-id={tagItem.id} className={`btn-tag btn-hashtag btn-sm ${tag && tagItem.id === tag.id ? 'active' : ''} ${item.exclamation ? 'exclamation' : ''} ${item.ok ? 'ok' : ''} ${item.question ? 'question' : ''} ${item.info ? 'info' : ''}`} key={tagItem.id} onClick={(e) => handleTagClick(tagItem, e)} size="sm" variant="outline-dark">#{ tagItem.name }</Button>
                        )))}
                    </ButtonGroup>
                    <ButtonGroup className="mt-1">
                    { item.tags.map((tagItem) => (tagItem.type === '@' && (
                        <Button tag-id={tagItem.id} className={`btn-tag btn-sm ${tag && tagItem.id === tag.id ? 'active' : ''} ${item.exclamation ? 'exclamation' : ''} ${item.ok ? 'ok' : ''} ${item.question ? 'question' : ''} ${item.info ? 'info' : ''}`} key={tagItem.id} onClick={(e) => handleTagClick(tagItem, e)} size="sm" variant="outline-secondary">@{ tagItem.name }</Button>
                    )))}
                    </ButtonGroup>
                </>
            )}
            { !item.readonly && (
                <>
                    <ButtonGroup>
                        { item.tags.map((tagItem) => (tagItem.type === '#' && (
                            <Button tag-id={tagItem.id} className={`btn-tag btn-hashtag btn-sm ${tagItem.name} ${item.exclamation ? 'exclamation' : ''} ${item.ok ? 'ok' : ''} ${item.question ? 'question' : ''} ${item.info ? 'info' : ''}`} key={tagItem.id} onClick={(e) => handleTagSelect(item.id, item, tagItem)} size="sm" variant="outline-dark">#{ tagItem.name } <XCircleFill className="mt--2" /></Button>
                        )))}
                    </ButtonGroup>
                    <ButtonGroup>
                    { item.tags.map((tagItem) => (tagItem.type === '@' && (
                        <Button tag-id={tagItem.id} className={`btn-tag btn-sm ${tagItem.name} ${item.exclamation ? 'exclamation' : ''} ${item.ok ? 'ok' : ''} ${item.question ? 'question' : ''} ${item.info ? 'info' : ''}`} key={tagItem.id} onClick={(e) => handleTagSelect(item.id, item, tagItem)} size="sm" variant="outline-secondary">@{ tagItem.name } <XCircleFill className="mt--2" /></Button>
                    )))}
                    </ButtonGroup>
                </>
            )}
            { !item.readonly && (
            <Dropdown align={{ lg: 'start' }} as={ButtonGroup} className={`btn-addtag ${item.exclamation ? 'exclamation' : ''} ${item.ok ? 'ok' : ''} ${item.question ? 'question' : ''} ${item.info ? 'info' : ''}`} >
                <Dropdown.Toggle as={tagToggle} />
                <Dropdown.Menu as={tagMenu}>
                    { tags.map((tagItem, tagIndex) => (
                        <Dropdown.Item eventKey={tagIndex} key={tagItem.id} onClick={(e) => handleTagSelect(id, item, tagItem)}>
                            { tagItem.name }
                            { item.tags.find(t => t.id === tagItem.id) ? <Check2 /> : '' }
                        </Dropdown.Item>    
                    )) }
                </Dropdown.Menu>
            </Dropdown>
            )}
        </div>
    );
}
 
export default React.memo(NoteTags);