import './note-options.css';
import React, { forwardRef, memo, useCallback, useRef, useState } from 'react';
import { BiCheckCircle, BiCheckSquare, BiErrorCircle, BiHelpCircle, BiInfoCircle, BiStar, BiSquare} from "react-icons/bi";
import { AlarmFill, App, Check2Square, CheckCircle, CheckCircleFill, Dot, ExclamationCircle, ExclamationCircleFill, InfoCircle, InfoCircleFill, Pencil, PinAngle, PinAngleFill, QuestionCircle, QuestionCircleFill, Star, StarFill, ToggleOff, ToggleOn } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DatePicker, { registerLocale, setDefaultLocale } from  "react-datepicker";
import de from 'date-fns/locale/de';
import Moment from 'react-moment';
import moment from 'moment'
 
const NoteOptions = ({display, id, item, handleOptionSelect, handleDateSelect, inputDueDatePickerRef}) => {
    const [dateSelected, setDateSelected] = useState(item.toDateDueAt)
    const RenderDatePickerBtn = forwardRef(({ onClick, value }, ref) => {
        return (
            <>
                {/* <button className="datepicker-btn" onClick={onClick} ref={ref}><AlarmFill className="me-1" /><span>{' '}{value}</span></button> */}
                <AlarmFill className="me-1 mt--2" />
                <input className="datepicker-btn" onClick={onClick} ref={ref} type="button" defaultValue={value} />
            </>
        )
    });

    const handleInputChange = (date, e) => {
        console.log(date, inputDueDatePickerRef.current)
        // inputDueDatePickerRef.current.state.preSelection = date
        setDateSelected(date)
        // inputDueDatePickerRef.current.input.value = moment(date).hour(18).minute(0).second(0).utc().format('DD. MMM YY')
        // inputDueDatePickerRef.current.setSelected(date)
    }

    return (
        <div id="note-options" className={`${item.readonly ? 'mt-1' : ''}`}>
            {/* No Task */}
            { (!item.readonly && item.check === null) && (
            <Button className={`option-btn`} onClick={() => {handleOptionSelect(id, item, 'check')}} size="md" tabIndex="-1" variant="link">
                <BiSquare className="mt--2" />
            </Button>
            )}
            {/* Updated at */}
            { (item.readonly && item.check === null && display !== 'text') && (
                <div className="mt-1 float-start" style={{'color':'#ccc', 'fontStyle':'italic'}}>
                    <Pencil className="mt--2" />
                    <Moment className="ms-2" format="DD. MMM yyyy">{item.toDateUpdatedAt}</Moment>
                </div>
            )}
            {/* Incomplete Task */}
            { (item.readonly && item.check === false) && (
                <div className="mt-1 float-start">
                    <BiSquare className="mt--2 me-1" />
                    <Moment format="DD. MMM yyyy">{item.toDateDueAt}</Moment>
                </div>
            ) }
            {/* Complete Task */}
            { (item.readonly && item.check === true) && (
                <div className="mt-1 float-start">
                    <BiCheckSquare className="mt--2 me-1" />
                    <Moment format="DD. MMM yyyy">{item.toDateDueAt}</Moment>
                </div>
            ) }
            { (!item.readonly && item.check === true) && (
                <>
                    <Button className={`option-btn active`} onClick={() => {handleOptionSelect(id, item, 'check')}} size="md" tabIndex="-1" variant="link">
                        <BiCheckSquare className="mt--2" />
                    </Button>
                    <Moment format="DD. MMM yyyy" style={{'verticalAlign':'text-top'}}>{item.toDateDueAt}</Moment>
                </>
            )}
            { (!item.readonly && item.check === false) && (
                <>
                    <Button className={`option-btn active`} onClick={() => {handleOptionSelect(id, item, 'check')}} size="md" tabIndex="-1" variant="link">
                        <BiSquare className="mt--2" />
                    </Button>
                    <DatePicker customInput={<RenderDatePickerBtn /> } dateFormat="dd. MMM yy" locale="de" selected={dateSelected} showPopperArrow={false} onChange={handleInputChange} ref={inputDueDatePickerRef} />
                </>
            )}
            { item.readonly && item.star && (
                <div className="mt-1 float-start">
                    <StarFill className="mt--2 ms-1" />
                </div>
            )}
            { (!item.readonly) && (
                <Button className={`option-btn ${item.star === false ? 'active' : ''}`} onClick={() => {handleOptionSelect(id, item, 'star')}} size="md" tabIndex="-1" variant="link">
                    { item.star && <StarFill className={item.readonly ? '' : 'mt--2'} /> }
                    { !item.star && <Star className="mt--2" /> }
                </Button>
            )}
            { (!item.readonly) && (
                <Button className="option-btn" onClick={() => {handleOptionSelect(id, item, 'exclamation')}} size="md" tabIndex="-1" variant="link">
                    { item.exclamation && <ExclamationCircleFill className="mt--2" /> }
                    { !item.exclamation && <ExclamationCircle className="mt--2" /> }
                </Button>
            )}
            { (!item.readonly) && (
                <Button className="option-btn" onClick={() => {handleOptionSelect(id, item, 'question')}} size="md" tabIndex="-1" variant="link">
                    { item.question && <QuestionCircleFill className="mt--2" /> }
                    { !item.question && <QuestionCircle className="mt--2" /> }
                </Button>
            )}
            { (!item.readonly) && (
                <Button className="option-btn" onClick={(e) => { handleOptionSelect(id, item, 'info') }} size="md" tabIndex="-1" variant="link">
                    { item.info && <InfoCircleFill className="mt--2" /> }
                    { !item.info && <InfoCircle className="mt--2" /> }
                </Button>
            )}
            { (!item.readonly) && (
                <Button className="option-btn" onClick={() => {handleOptionSelect(id, item, 'ok')}} size="md" tabIndex="-1" variant="link">
                    { item.ok && <CheckCircleFill className="mt--2" /> }
                    { !item.ok && <CheckCircle className="mt--2" /> }
                </Button>
            )}
            { item.readonly && item.pin && (
                <div className="mt-1 float-start">
                    <PinAngleFill className="mt--2 ms-1" />
                </div>
            )}
            { (!item.readonly) && (
                <Button className="option-btn" onClick={() => {handleOptionSelect(id, item, 'pin')}} size="md" tabIndex="-1" variant="link">
                    { item.pin && <PinAngleFill className="mt--2" /> }
                    { !item.pin && <PinAngle className="mt--2" /> }
                </Button>
            )}
        </div>
    )
}
 
export default NoteOptions;