import axios from "axios";
import configData from "../config.json";

const API_URL = `${configData.API_URL}/auth`;

class AuthService {
    login(email, password) {
        return axios.post(`${API_URL}/login`, {
              email,
              password
          })
          .then(response => {
                console.log('auth-service_login', response.data)
                localStorage.setItem("user", JSON.stringify(response.data));

                return response.data;
        });
    }

    logout() {
        localStorage.removeItem("user");
    }

    register(username, email, password) {
        return axios.post(`${API_URL}/signup`, {
            username,
            email,
            password
        });
    }

    getCurrentUser() {
        let user = JSON.parse(localStorage.getItem('user'));
        // console.log('auth-service_get-current-user', user)
        return user
    }

    getFreshToken() {
        const user = JSON.parse(localStorage.getItem('user'))

        if (user && user.access_token) {
            const token = JSON.parse(atob(user.access_token.split('.')[1]))

            if (Date.now() >= token.exp * 1000) {
                console.log('auth-service_get-fresh-token_expired')
                return this.refresh(user.access_token).then(response => {
                    if (response) {
                        return Promise.resolve(response);
                    }
                    else {
                        return Promise.reject(null)
                    }
                })
            }
            return Promise.resolve(user.access_token)
        }
        else {
            return Promise.reject(null)
        }
    }

    refresh(jwt) {
        console.log('auth-service_refresh_jwt')
        return axios.get(`${API_URL}/refresh?token=${jwt}`, {
            transformRequest: (data, headers) => {
                delete headers.common['Authorization']
                return data
        }})
        .then(response => {
            console.log('auth-service_refresh_success')
            response.data.access_token = response.data.token ? response.data.token : response.data.access_token
            if (response.data.access_token) {
                localStorage.setItem("user", JSON.stringify(response.data));
                return response.data.access_token
            }
            return null
        })
        .catch(error => {
            console.log('auth-service_refresh_error', error.response.data.message)
            return null
        })
    }
}

export default new AuthService();
