import './shell.css'
import './notes.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'quill-mention/dist/quill.mention.css'
import 'react-quill/dist/quill.bubble.css'
import 'react-datepicker/dist/react-datepicker.css'

import React, { useCallback, useEffect, memo, useMemo, useState, useRef } from 'react'
import { LightgalleryItem } from "react-lightgallery";
import ReactQuill, {Quill} from 'react-quill'
import Dropdown from 'react-bootstrap/Dropdown'
import FormControl from 'react-bootstrap/FormControl';
import moment from 'moment'
import Moment from 'react-moment';
import NoteOptions from './note-options'
import ListMenuModal from './note-menu'
import NoteTags from './note-tags'
import { Calendar3Event, CaretDownFill, CaretLeftFill, CaretRightFill, ChatLeftDots, ChatLeftTextFill, DashCircleDotted, PlusCircleDotted, JournalText, JournalCheck, Pen, Pencil, PencilSquare }  from 'react-bootstrap-icons'
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ProgressBar from 'react-bootstrap/ProgressBar'
import DatePicker, { registerLocale, setDefaultLocale } from  "react-datepicker"
import 'moment/locale/de';

import de from 'date-fns/locale/de';
registerLocale('de', de)
moment().locale('de');


const NoteContentInput = ({item, modules, handleContentChange}) => (
    <ReactQuill 
        bounds={`#content-${item.id}`}
        id={`content-${item.id}`}
        key={`${item.id}_content`}
        modules={modules}
        // onBlur={(previousRange, source, editor) => handleBlurEditor(item, previousRange, source, editor)} 
        onChange={(content, delta, source, editor) => handleContentChange(item, content, delta, source, editor)} 
        // onFocus={(range, source, editor) => handleFocusEditor(item, range, source, editor)}
        placeholder="Hier anfangen zu tippen..."
        readonly={item.readonly}
        // ref={(element) => {
        //     quillRef.current = element
        // }}
        // spell-check="false"
        theme="bubble" 
        value={item.content} />
)
const NoteImagePaste = ({item, modules, handleImagePaste}) => (
    <ReactQuill 
        bounds={`#content-${item.id}`}
        id={`image-${item.id}`}
        placeholder="Screenshot einfügen (Ctrl+V)"
        onChange={(content, delta, source, editor) => handleImagePaste(item, content, delta, source, editor)} 
        theme="bubble" 
        value={item.image} />
)
const NoteTitleInput = ({item, inputTitleRef}) => (
    <input className="note-input-title py-0" id={`${item.id}_title`} key={`${item.id}_title`} placeholder="Titel..." ref={inputTitleRef} defaultValue={item.title} />
)
const fetchDebouncedMentions = (searchTerm, renderList, mentionChar, tags) => { 
    let values;

    if (mentionChar === "@") {
        // values = [ { id: 1, value: 'Fredrik Sundqvist' }, { id: 2, value: 'Patrik Sjölin' } ]
        values = tags.filter(item => { 
            return item.type === '@'
        }).map(item => {
            return { id: item.id, value: item.name}
        })
    } 
    else {
        // values = [ { id: 3, value: 'Fredrik Sundqvist 2' }, { id: 4, value: 'Patrik Sjölin 2' } ]            
        values = tags.filter(item => { 
            return item.type === '#'
        }).map(item => {
            return { id: item.id, value: item.name}
        })
    }
    if (searchTerm.length === 0) {
        renderList(values, searchTerm);
    } 
    else {
        const matches = [];
        for (let i = 0; i < values.length; i++) {
            if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) {
                matches.push(values[i]);
            }
            renderList(matches, searchTerm);
        }
    }
}
const handleAddItem = (item, tags) => {
    console.log('notecontext_mention_on-select', item.value)
    // let found = tags.filter(filterItem => {
    //     return filterItem.id == item.id
    // })
    // if (found) {
    //     let tagItem = found[0]
    //     handleEditmodeTagSelect(editmode, tagItem)
    // }
    // let found = filtercontext.tagging.tags.filter(tag => {
    //     return tag.id == item.id
    // })
    // if (found.length) {
    //     found.forEach(tag => {
    //         tag.ticked = true
    //         filtercontext.tagging.ticks.push(tag)
    //     })
    // }
}
const NoteModules = (handleAddItem, fetchDebouncedMentions, tags) => ({
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { list: "check" }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],
        // [{ 'align': [] }],
        ['clean'],
        ['link']], //, 'image'
    clipboard: {
        matchVisual: false,
    },
    mention: {
        allowedChars: /^.*$/,
        mentionDenotationChars: ["@", "#"],
        // mentionDenotationChars: mentionData.map(md => md.prefix),
        source: (searchTerm, renderList, mentionChar) => fetchDebouncedMentions(searchTerm, renderList, mentionChar, tags),
        onSelect: (item, insertItem) => {
            handleAddItem(item)
            insertItem(item)
        },
        // renderItem: (item, searchTerm) => getHighlightedComponent(item.display_dropdown, searchTerm, true),
        isolateCharacter: true,
    },
})

/**
 * Item
 */
 const Item = memo(({
    display,
    id,
    item, 
    tag,
    tags, 
    task,
    type,
    handleReadonlyToggle, 
    handleChildDateSelect, 
    handleContentSave,
    handleContentCancel,
    handleContentDelete,
    handleDateSelect,
    handleExpandThreadToggle,
    handleReadAllToggle,
    handleAllShowThreadsToggle,
    handleTagSelect, 
    handleOptionSelect,
    inputDueDatePickerRef,
    inputTitleRef,
    setTag
}) => {
// console.log('shell_item', id)
let quillRef = useRef(null)

const [ listMenuElem, setListMenuElem ] = useState(null)
const [ showListMenuModal, setShowListMenuModal ] = useState(false)

const memoizedValue = useMemo(() => NoteModules(handleAddItem, fetchDebouncedMentions, tags), [
    handleAddItem,
    fetchDebouncedMentions,
    tags,
])

const handleContentChange = (item, content, delta, source, editor) => {
    if (source === 'user') {
        let content = editor.getHTML()
        if (item.content !== content) {
            item.content = content
            console.log('shell_item_handle-content-change', item, source)
        }
    }
}

const handleImagePaste = (item, content, delta, source, editor) => {
    // console.log('note-item_handle-image-paste', item, content, source)
    if (source === 'user') {
        let content = editor.getHTML()
        if (item.image !== content) {
            item.image = content
            console.log('shell_item_handle-image-paste', item, source)
        }
    }
}

const handleMenuClick = (e) => {
    console.log('note-item_handle_menu_click', e.target.localName)
    // if (e.target.localName === 'li') {
    //     let children = []
    //     let elementHtml = e.target.outerHTML
    //     let elementIndex = 0
    //     let html = ''
    //     let parent = e.target.parentElement
    //     let parentHtml = parent.outerHTML
    // }
    // handleListMenuModalToggle(true, e)
        let bounds = e.target.parentElement.getBoundingClientRect();
        let left = e.clientX - bounds.left;
        let right = e.clientX - bounds.right;
        let top = e.clientY - bounds.top;

        console.log(left, right)

    if (e.target.localName === 'li') {
        if (right < 0 && right > -15) {
            setListMenuElem(e)
            setShowListMenuModal(true)
        }
    }
}

const onDateSelect = (item, days, e) => {
    let dueAt = moment(item.toDateDueAt).add(days, 'days')
    handleDateSelect(item, 'toDateDueAt', dueAt, true)
}

const onReadonlyToggle = (item, e) => {
    // console.log('shell_parent_handle-readonly-toggle', item)
    e.stopPropagation()
    if (!item.readonly) {
        console.log('shell_item_on-readonly-toggle_stop-propagation', item)
    }
    else {
        console.log('shell_item_handle-readonly-toggle', item)
        handleReadonlyToggle(item)
        // setTimeout(() => {
        //     console.log('shell_parent_handle-readonly-toggle_ref', quillRef)
        //     quillRef.current.focus()
        // }, 500)
    }
}
const onShowAllThreadsToggle = (item, e) => {
    // console.log('shell_parent_handle-readonly-toggle', item)
    e.stopPropagation()
    if (!item.readonly) {
        console.log('shell_item_on-readonly-toggle_stop-propagation', item)
    }
    else {
        console.log('shell_item_handle-readonly-toggle', item)
        handleAllShowThreadsToggle(item)
        // setTimeout(() => {
        //     console.log('shell_parent_handle-readonly-toggle_ref', quillRef)
        //     quillRef.current.focus()
        // }, 500)
    }
}

const RenderDatePickerBtn = React.forwardRef(({ value, onClick }, ref) => (
    <button className="datepicker-btn" onClick={onClick} ref={ref}>{value}</button>
))

const RenderContentPreview = ({item, task, handleReadAllToggle}) => {
    let parsed = '';

    if (item.readonly) {
        if (display === 'list' && item.readAll && (item.parsedTasks || item.numTasks > 0)) {
            if (item.parsedTasks) {
                parsed = (
                    <div className="ql-editor preview" dangerouslySetInnerHTML={{ __html: item.parsedTasks }} />
                )
            }
        }
        // else if (item.checked === false && item.parsedTasks) {
        //     parsed = (
        //         <>
        //             <div className="ql-editor preview" dangerouslySetInnerHTML={{ __html: item.readAll ? item.content : item.parsedTasks }} />
        //         </>
        //     )
        // }
        else if (!task || !item.parsedTasks) {
            parsed = (
                <>
                    <div className="ql-editor preview" dangerouslySetInnerHTML={{ __html: item.readAll ? item.content : item.parsedContent }} onClick={(e) => handleMenuClick(e)} />
                    { (!item.readAll && item.content && item.parsedContent && item.content.length > item.parsedContent.length) && (
                        <div className="note-content-more" onClick={(e) => { handleReadAllToggle(item, e)}}>...</div>
                )}
                </>
            )
        }
    }

    return parsed
}

const RenderHeaderPreview = ({item, task, onReadonlyToggle}) => {
    let parsed;

    if (display === 'list') {
        parsed = (
            <div className="note-main-title-clickable py-1 mt-1 col-md-3" onClick={(e) => { onReadonlyToggle(item, e) }}>
                <span className="note-title">{item.title || 'Ohne Titel'}</span>
            </div>
        )
    }
    else if (display === 'column') {
        parsed = (
            <div className="note-main-title-clickable py-1 mt-1" onClick={(e) => { onReadonlyToggle(item, e) }}>
                <span className="note-title">{item.title || 'Ohne Titel'}</span>
            </div>
        )
    }
    else {
        parsed = (
            <div className="note-main-title-clickable" onClick={(e) => { onReadonlyToggle(item, e) }}>
                <div className="note-main-title pt-3">
                    <div className="mx-4">
                        <span className="note-title" style={{'fontSize':'20px'}}>{item.title || 'Ohne Titel'}</span>
                        {/* <Button className="btn btn-edit-title" size="sm" title="Bearbeiten" variant="primary"><PencilSquare /></Button> */}
                    </div>
                </div>
                { (item.check === false && item.id !== null) && (
                    <div className="ms-4">
                        <RenderTaskProgress item={item} />
                    </div>
                )}
                { item.id !== null && (
                    <div className="ps-4 pb-3">
                        <span><Moment format="DD. MMM yyyy">{item.toDateCreatedAt}</Moment></span>
                        { item.created_at !== item.updated_at && (
                            <span style={{'color':'#bbb'}}>&nbsp;(<Pencil className="mt--2" style={{'padding':'2px 0'}} title="zuletzt geändert am" /><Moment format="DD. MMM yyyy">{item.updated_at}</Moment>)</span>
                        )}
                    </div>
                )}
            </div>
        )
    }

    return parsed
}

const RenderImages = ({item}) => {
    let parsed = ''
    if (item.images && item.images.length > 0) {
        parsed = (
            <div style={{'position':'relative'}}>
                <div className="ps-4 image-gallery-container">
                    { item.images.map(itemImage => (
                        <LightgalleryItem group="any" key={`img-${itemImage.id}`} src={itemImage.source}>
                            <img className="image-gallery-item" src={itemImage.source} />
                        </LightgalleryItem>
                    )) }
                    <br style={{'clear':'both'}} />
                </div>
                <div className="image-gallery-container-shadow"></div>
            </div>
        )
    }

    return parsed
}

const RenderOptionbar = ({item, task, tag, setTag, handleTagSelect, handleOptionSelect, handleDateSelect}) => {
    let parsed

    if (item.readonly && (display === 'list' || display === 'column')) {
        parsed = (
            <>
                <div className={`${display === 'column' ? '' : 'col-md-5'}`}>
                    <NoteTags id={item.id} item={item} tag={tag} tags={tags} handleTagSelect={handleTagSelect} setTag={setTag} />
                </div>
                <div className={`${display === 'column' ? '' : 'col-md-3'}`}>
                    <span className={`${!item.star ? 'me-4' : ''}`}>
                        <NoteOptions display={display} id={item.id} item={item} handleOptionSelect={handleOptionSelect} handleDateSelect={handleDateSelect} inputDueDatePickerRef={inputDueDatePickerRef} />
                    </span>
                    { item.check !== null && (
                        <ButtonGroup className={`mt-1 ${display === 'column' ? 'ms-2': 'float-end'}`}>
                            <Button className="btn-add-days" onClick={(e) => {onDateSelect(item, 1, e)}} size="sm" variant="secondary">+1</Button>
                            <Button className="btn-add-days" onClick={(e) => {onDateSelect(item, 7, e)}} size="sm" variant="secondary">+7</Button>
                        </ButtonGroup>
                    )}
                </div>
            </>
        )
    }
    else {
        parsed = (
            <div className="px-4">
                <div className="note-options-container">
                    <NoteTags id={item.id} item={item} tag={tag} tags={tags} handleTagSelect={handleTagSelect} setTag={setTag} />
                    <NoteOptions display={display} id={item.id} item={item} handleOptionSelect={handleOptionSelect} handleDateSelect={handleDateSelect} inputDueDatePickerRef={inputDueDatePickerRef} />
                    <br className="clearboth" />
                </div>
            </div>
        )
    }

    return parsed
}

const RenderPreview = ({handleContentSave, handleDateSelect, handleExpandThreadToggle, handleOptionSelect, handleReadAllToggle, handleTagSelect, item, setTag, tag, task}) => {
    const parseUnclickedLi = (start, len, children, checked) => {
        let html = ''
        html += `<ul data-checked="${checked}">`
        for (let i = start; i < len; i++) {
            html += children[i]
        }
        html += `</ul>`
        return html
    }
    const parseClickedLi = (children, index, checked) => {
        return `<ul data-checked="${checked == 'true' ? false : true}">${children[index]}</ul>`
    }
    const handleCheckboxClick = (noteItem, e) => {
        console.log('note-item_render-preview_handle-checkbox-click', e.target.parentElement.outerHTML)
        if (e.target.localName === 'li') {
            let children = []
            let elementHtml = e.target.outerHTML
            let elementIndex = 0
            let html = ''
            let parent = e.target.parentElement
            let parentHtml = parent.outerHTML
            let checked = e.target.parentElement.attributes['data-checked'].value
            for (let i = 0; i < parent.children.length; i++) {
                let childHtml = parent.children[i].outerHTML
                if (childHtml === elementHtml) {
                    elementIndex = i
                }
                children.push(childHtml)
            }
            if (elementIndex === 0) {
                html+= parseClickedLi(children, elementIndex, checked)
                html+= parseUnclickedLi(1, children.length, children, checked)

            }
            else {
                html+= parseUnclickedLi(0, elementIndex, children, checked)
                html+= parseClickedLi(children, elementIndex, checked)
                if (elementIndex < children.length - 1) {
                    html+= parseUnclickedLi(elementIndex + 1, children.length, children, checked)
                }
            }
            noteItem.content = noteItem.content.replace(parentHtml, html)
            noteItem.parsedTasks = noteItem.parsedTasks.replace(parentHtml, html)
            noteItem.children.forEach(child => {
                child.content = child.content.replace(parentHtml, html)
                child.parsedTasks = child.parsedTasks.replace(parentHtml, html)
            })
            handleContentSave(noteItem, e, false)
        }
    }

    let parsed
    if (item.readonly && (display === 'list' || display === 'column')) { // Tasks/Dashboard und Wochenansicht
        parsed = (
            <div className="note-main-preview">
                {/* Wochenansicht */}
                <div className={`${display==='column' ? 'ms-1' : 'ms-3'} py-1 row`}> 
                    <RenderHeaderPreview item={item} task={task} onReadonlyToggle={onReadonlyToggle} />
                    <RenderOptionbar item={item} task={task} tag={tag} setTag={setTag} handleTagSelect={handleTagSelect} handleOptionSelect={handleOptionSelect} handleDateSelect={handleDateSelect} />
                    <RenderTaskStatus item={item} handleReadAllToggle={handleReadAllToggle} />
                </div>
                { item.readAll && (
                    <>
                        <div className="px-4 py-3" onClick={(e) => handleCheckboxClick(item, e)} style={{'background':'#fafafa', 'borderTop':'solid 1px #eee'}}>
                            <RenderContentPreview item={item} task={task} handleReadAllToggle={handleReadAllToggle} />
                            <RenderThreadPreview item={item} task={task} handleExpandThreadToggle={handleExpandThreadToggle} />
                        </div>
                        {/* <RenderImages item={item} /> */}
                    </>
                )}
            </div>
        )
    }
    else {
        parsed = (
            <>
                <RenderHeaderPreview item={item} task={task} onReadonlyToggle={onReadonlyToggle} />
                <RenderOptionbar item={item} task={task} tag={tag} setTag={setTag} handleTagSelect={handleTagSelect} handleOptionSelect={handleOptionSelect} handleDateSelect={handleDateSelect} />
                <div className="px-4 py-3">
                    <RenderContentPreview item={item} task={task} handleReadAllToggle={handleReadAllToggle} />
                </div>
                <RenderThreadPreview item={item} task={task} handleExpandThreadToggle={handleExpandThreadToggle} />
                <RenderImages item={item} />
            </>
        )
    }

    return parsed
}

const RenderTaskProgress = ({item}) => {
    let taskWidth = 100 / item.numTasks
    let tsks = [];
    for (let i = 0; i < item.numTasks; i++) {
        tsks.push(i);
    }

    return (
        <div className="progress-bar" style={{'height':'3px', 'width':'50%'}}>
            <div className="progress-bar-task-container">
                { tsks.map((tsk, index) => (
                    <div key={`${item.id}_tsk-${index}`} className="progress-bar-task" style={{'width':`${taskWidth}%`}}></div>
                ))}
            </div>
            <ProgressBar now={item.completeTasksPercent} style={{'height':'3px'}} />
        </div>
    )
}

const RenderTaskStatus = ({item, handleReadAllToggle}) => {
    return (
        <div className="col-md-1 mt-1 py-1 text-center">
            <span onClick={(e) => handleReadAllToggle(item)} style={{'cursor':'pointer'}}>{item.checkedTasks}/{item.numTasks}</span>
        </div>
    )
}

const RenderThreadPreview = ({item, task, handleExpandThreadToggle}) => {
    let parsed;
    if (!item.children || !item.children.length) {
        parsed = ''
    }
    else if ((display === 'list' || display === 'column') && item.readAll && (item.parsedTasks || item.numTasks)) {
        parsed = (
            <>
                { item.children.map((child, index) => (
                    child.parsedTasks && (
                        <div className="ql-editor preview" dangerouslySetInnerHTML={{ __html: child.parsedTasks }} key={`${item.id}_${child.id}`} />
                    )
                )) }
            </>
        )
    }
    else {
        if (!item.showAllThreads) {
            parsed = (
                <div className="ms-2 py-2">
                    <div className="note-threads-toggle" onClick={(e) => onShowAllThreadsToggle(item, e)}><CaretRightFill className="mt--2" /> <ChatLeftTextFill /> { item.children.length} Thread(s)</div>
                    <br className="clearfix" />
                </div>
            )
        }
        else {
            parsed = (
                <div className="mt-2">
                    <div className="ms-2 pb-3">
                        <div className="note-threads-toggle" onClick={(e) => onShowAllThreadsToggle(item, e)}><CaretDownFill className="mt--2" /> <ChatLeftTextFill /> { item.children.length} Thread(s)</div>
                        <br className="clearfix" />
                    </div>
                    {/* <div className="note-thread-heading"><span><ChatLeftTextFill className="mt--2" style={{'color':'#777'}} /></span></div> */}
                    <div className="note-thread-wrapper ps-4 py-3">
                        { item.id !== null && item.children.map((child, index) => (
                            <div key={`${item.id}_${child.id}`} className={`py-1 note-thread-container`}>
                                <div className={`thread-created-at-clickable`} onClick={(e) => { handleExpandThreadToggle(item, child, e) }}>
                                    {/* {(!child.expanded) && (
                                        <CaretRightFill className="mt--2" style={{'color':'#777'}} />
                                    )}
                                    {(child.expanded) && (
                                        <CaretDownFill className="mt--2" style={{'color':'#777'}} />
                                    )} */}
                                    <Moment className="ms-1" format="DD. MMM YYYY">{child.created_at}</Moment>
                                </div>
                                { (child.expanded === true) && (
                                    <div className={`p-3 ms-3 mt-1 note-thread-content`}>
                                        <div className="ql-editor preview" dangerouslySetInnerHTML={{ __html: child.content }} />
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )
        }
    }
    return parsed
}

return (
    <div id={`note-${item.id}`} className={`note ${display} ${task ? 'task' : ''} ${item.pin ? 'pin' : ''} ${item.id === null ? 'note-input editmode' : ''} ${item.id !== null && !item.readonly ? 'editmode':  ''} ${item.exclamation ? 'exclamation' : ''} ${item.ok ? 'ok' : ''} ${item.question ? 'question' : ''} ${item.info ? 'info' : ''}`}>
        <div>
            <ListMenuModal listMenuElem={listMenuElem} setShowListMenuModal={setShowListMenuModal} showListMenuModal={showListMenuModal} />

            {/* Preview */}
            { item.readonly && (
                <RenderPreview 
                    handleContentSave={handleContentSave}
                    handleDateSelect={handleDateSelect} 
                    handleExpandThreadToggle={handleExpandThreadToggle}
                    handleOptionSelect={handleOptionSelect} 
                    handleReadAllToggle={handleReadAllToggle}
                    handleTagSelect={handleTagSelect} 
                    item={item} 
                    setTag={setTag} 
                    tag={tag} 
                    task={task} 
                    />
            )}

            {/* Editmode */}
            { !item.readonly && (
                <div className={`${item.id === null ? 'note-main-input' : ''}`}>

                    <div className="note-main-title pt-3">
                        <div className="mx-4">
                            <NoteTitleInput item={item} inputTitleRef={inputTitleRef} />
                            {/* <NoteInputTitle item={item} inputTitleRef={inputTitleRef} /> */}
                        </div>
                    </div>
                    { item.id !== null && (
                        <div className="ps-4 pb-3">
                            <DatePicker customInput={<RenderDatePickerBtn />} dateFormat="dd. MMM yyyy" locale="de" selected={item.toDateCreatedAt} showPopperArrow={false} onChange={(date, e) => handleDateSelect(item, 'toDateCreatedAt', date)} />
                            { item.created_at !== item.updated_at && (
                                <span style={{'color':'#bbb'}}>&nbsp;(<Pencil className="mt--2" style={{'padding':'2px 0'}} title="zuletzt geändert am" /><Moment format="DD. MMM yyyy">{item.updated_at}</Moment>)</span>
                            )}
                        </div>
                    )}

                    <RenderOptionbar item={item} task={task} tag={tag} setTag={setTag} handleTagSelect={handleTagSelect} handleOptionSelect={handleOptionSelect} handleDateSelect={handleDateSelect} />

                    { item.id === null && (
                        <Button className="btn btn-save" onClick={(e) => handleContentSave(item, e)} size="sm" tabIndex="-1" title="Speichern" variant="primary">Alt+Shift+S</Button>
                    )}
                    { (item.id !== null) && (
                    <Dropdown as={ButtonGroup} align={{lg: 'start'}} className="btn-save">
                        <Button onClick={(e) => handleContentSave(item, e)} size="sm" title="Speichern" variant="primary">Alt+Shift+S</Button>
                        <Dropdown.Toggle split variant="primary" id="dropdown-custom-2" />
                        <Dropdown.Menu className="">
                            <Dropdown.Item eventKey="1" onClick={(e) => handleContentCancel(item.id, item.type)} title="Abbrechen">ESC</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item eventKey="2" onClick={(e) => handleContentDelete(item.id, item.type)} title="Löschen">Alt+Shift+D</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown> )}
                    <div className={`px-4 py-3 mt-3 mb-2 note-main-content ${item.id === null ? 'note-input-container' : ''}`} onClick={(e) => handleMenuClick(e)}>
                        <NoteContentInput item={item} modules={memoizedValue} handleContentChange={handleContentChange} quillRef={quillRef} />
                    </div>
                    { item.id !== null && (
                        <>
                            {/* <div className="note-thread-heading"><span><ChatLeftTextFill className="mt--2" style={{'color':'#777'}} /></span></div> */}
                            <div className="note-thread-wrapper pe-4 ps-4 pb-3 mt-2">
                                {/* New Thread */}
                                <div key="-2" className={`pt-3 note-thread-container`}>
                                    <div className="thread-created-at">Jetzt</div>
                                    <div className={`p-3 ms-2 mt-1 note-thread-content`}>
                                        <NoteContentInput item={item.thread} modules={memoizedValue} handleContentChange={handleContentChange} />
                                    </div>
                                </div>
                                {/* Threads */}
                                { item.children.map((child, index) => (
                                    <div key={`${item.id}_${child.id}`} className={`mt-2 note-thread-container`}>
                                        <div className="thread-created-at">
                                            <DatePicker customInput={<RenderDatePickerBtn />} dateFormat="dd. MMM yyyy" locale="de" selected={child.toDateCreatedAt} showPopperArrow={false} onChange={(date, e) => handleChildDateSelect(child.id, item.id, date)} />
                                        </div>
                                        <Button className="note-thread-delete" onClick={(e) => handleContentDelete(child.id, child.type)} size="sm" variant="link">löschen</Button>
                                        <div className={`p-3 ms-2 mt-1 note-thread-content`}>
                                            <NoteContentInput item={child} modules={memoizedValue} handleContentChange={handleContentChange} quillRef={quillRef} />
                                        </div>
                                    </div>
                                )) }
                            </div>
                            <div className="ps-4 py-2 paste-image">
                                <div style={item.image ? {'width':'200px'} : {}}>
                                    <NoteImagePaste item={item} modules={memoizedValue} handleImagePaste={handleImagePaste} quillRef={quillRef} />
                                </div>
                            </div>


                            <RenderImages item={item} />


                        </>
                    )}
                </div>
            )}
        </div>
    </div>
)
})

export default React.memo(Item);