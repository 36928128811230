
import React, { useCallback, useEffect, memo, useMemo, useState, useRef } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'



const ListMenuModal = memo(({listMenuElem, setShowListMenuModal, showListMenuModal}) => {
    const handleModalClose = () => {
        setShowListMenuModal(false)
    }
    const handleModalSave = () => {
        console.log('note-menu_handle-modal-save')
    }

    return (
        <Modal show={showListMenuModal} size="md" onHide={handleModalClose}>
            <Modal.Header closeButton>
                <Modal.Title>Bullet List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(listMenuElem && listMenuElem.target) && (
                    <div className="ql-editor preview" dangerouslySetInnerHTML={{ __html: listMenuElem.target.outerHTML }} />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleModalClose}>Abbrechen</Button>
                <Button variant="primary" onClick={handleModalSave}>Speichern</Button>
            </Modal.Footer>
        </Modal>
    )
})
export default React.memo(ListMenuModal);